.btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border-radius: 50px;
	border: 2px solid transparent;
	min-height: 40px;
	padding: 20px 100px;
	font-family: 'Graphik LCG';
	font-weight: bold;
	font-style: normal;
	font-size: 25px;
	line-height: 37px;
	text-align: center;
	outline: none;
	@media screen and (max-height: 899px) {
		padding: 15px 70px;
		font-size: 20px;
		line-height: 30px;
	}
	@media screen and (max-width: 1199.9px) {
		padding: 10px 50px;
		font-size: 16px;
		line-height: 26px;
	}
}
.btn-primary {
	color: $white;
    background: linear-gradient(-90deg, #fb8d01 0%, #ee1b00 100%);
    box-shadow: 0 0 60px 30px rgba(218, 68, 0, .3);
    border: 2px solid transparent;
    &:hover {
    	color: $white;
	    background: linear-gradient(-90deg, #fb8d01 0%, #ee1b00 100%);
	    border: 2px solid transparent;
	    box-shadow: none;
    }
    &:active {
    	color: $white;
	    background: linear-gradient(-90deg, #fb8d01 0%, #ee1b00 100%);
	    border: 2px solid transparent;
	    box-shadow: none;
    }
    &:focus {
    	color: $white;
	    background: linear-gradient(-90deg, #fb8d01 0%, #ee1b00 100%);
	    border: 2px solid transparent;
	    box-shadow: none;
    }
}