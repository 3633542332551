h1, .h1 {
	font-family: 'Graphik LCG';
	font-weight: bold;
	font-style: normal;
	font-size: 87px;
	line-height: 115px;
	@media screen and (max-height: 899px) {
		font-size: 60px;
		line-height: 90px;
	}
	@media screen and (max-width: 991.9px) {
		font-size: 60px;
		line-height: 90px;
	}
	@media screen and (max-width: 479.9px) {
		
	}
}
h2, .h2 {
	font-family: 'Graphik LCG';
	font-weight: bold;
	font-style: normal;
	font-size: 87px;
	line-height: 115px;
	@media screen and (max-height: 899px) {
		font-size: 45px;
		line-height: 65px;
	}
	@media screen and (max-width: 991.9px) {
		font-size: 45px;
		line-height: 65px;
	}
	@media screen and (max-width: 575.9px) {
		font-size: 35px;
		line-height: 55px;
	}
	@media screen and (max-width: 479.9px) {
		font-size: 25px;
		line-height: 45px;
	}
}
h3, .h3 {
	font-family: 'Roboto', sans-serif;
	font-weight: 700;
	font-style: normal;
	font-size: 40px;
	line-height: 40px;
	@media screen and (max-height: 899px) {
		font-size: 24px;
		line-height: 30px;
	}
	@media screen and (max-width: 991.9px) {
		font-size: 24px;
		line-height: 30px;
	}
	@media screen and (max-width: 575.9px) {
		font-size: 20px;
		line-height: 26px;
	}
	@media screen and (max-width: 479.9px) {
		font-size: 18px;
		line-height: 24px;
	}
}
h4, .h4 {
	@media screen and (max-width: 767.9px) {
		
	}
	@media screen and (max-width: 479.9px) {
		
	}
}
h5, .h5 {
	@media screen and (max-width: 767.9px) {
		
	}
	@media screen and (max-width: 479.9px) {
		
	}
}
p {
	font-weight: normal;
	font-style: normal;
	font-size: 18px;
	line-height: 40px;
	@media screen and (max-height: 899px) {
		font-size: 16px;
		line-height: 30px;
	}
	@media screen and (max-width: 991.9px) {
		font-size: 16px;
		line-height: 30px;
	}
	@media screen and (max-width: 575.9px) {
		font-size: 14px;
		line-height: 24px;
	}
	@media screen and (max-width: 479.9px) {
		font-size: 12px;
		line-height: 22px;
	}
}