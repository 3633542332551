.breadcrumbs-block {
	width: 100%;
	padding: 13px 0 10px;
	border-bottom: 1px solid #E9E9E9;
	.breadcrumbs {
		display: flex;
		align-items: baseline;
		span {
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 21px;
			color: $main;
		}
		a {
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 21px;
			color: $brand;
			position: relative;
			display: flex;
			align-items: baseline;
			margin-right: 10px;
			text-decoration: none;
			transition: all .25s ease-in-out;
			&:hover {
				opacity: .8;
			}
			&:after {
				content: '/';
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 21px;
				color: $brand;
				margin-left: 10px;
			}
		}
	}
}

@media screen and (max-width: 991px) {
	
}