.modal {
	.modal-content {
		background: url(../images/modal-bg.png);
	    background-size: cover;
	    background-repeat: no-repeat;
	    background-position: center bottom;
	    border: 1px solid rgba(89, 91, 146, .25);
	    border-radius: 0;
	    position: relative;	    
		.modal-body {
			padding: 45px 90px 90px;
			text-align: center;
			@media screen and (max-width: 1199.9px) {
				padding: 25px 20px 50px;
			}
			h2 {
				margin-bottom: 40px;			
		    	text-transform: uppercase;
		    	position: relative;
		    	display: flex;
		    	align-items: center;
		    	justify-content: center;
		    	min-height: 200px;	
		    	z-index: 1;
		    	@media screen and (max-width: 1199.9px) {
					min-height: 100px;
					margin-bottom: 30px;		
				}
				@media screen and (max-width: 991.9px) {
					font-size: 38px;
				}	
				@media screen and (max-height: 899px) {
					min-height: 70px; 
				}   	
			}
			.sub-title {
	    		display: block;
			    font-family: "Graphik LCG";
			    font-weight: 700;
			    font-style: normal;
			    font-size: 125px;
			    line-height: 115px;
			    text-transform: uppercase;
			    position: absolute;
			    left: 0;
			    top: 85px;
			    color: transparent;
			    -webkit-text-fill-color: transparent;
			    -webkit-text-stroke-color: rgba(89, 91, 146,.25);
			    -webkit-text-stroke-width: 1px;
			    z-index: 0;
			    width: 100%;
			    @media screen and (max-height: 899px) {
					font-size: 80px;
			    	line-height: 100px;
			    	top: 30px;
				}
			    @media screen and (max-width: 1199.9px) {
					font-size: 80px;
			    	line-height: 100px;
			    	top: 25px;	
				}
				@media screen and (max-width: 991.9px) {
					font-size: 55px;
				}
	    	}
	    	form {
	    		width: 100%;
	    		max-width: 600px;
	    		margin: 0 auto;
	    		text-align: center;
	    		@media screen and (max-width: 1199.9px) {
					max-width: 500px;
				}
	    		.form-group {
	    			margin-bottom: 30px;
	    			@media screen and (max-height: 899px) {
						margin-bottom: 20px;
					}
					@media screen and (max-width: 1199.9px) {
						margin-bottom: 20px;
					}
	    		}
	    		.btn {
	    			margin-top: 40px;
	    			@media screen and (max-height: 899px) {
						margin-top: 10px;
					}
					@media screen and (max-width: 1199.9px) {
						margin-top: 10px;
					}
	    		}
	    	}
		}
		.btn-close {
			position: absolute;
			top: 30px;
			right: 30px;
			z-index: 99999;
			width: 30px;
		    height: 30px;
		    padding: 0;
		    color: #fff;
		    background: url(../images/btn-close.png) no-repeat center;		    
		    opacity: 1;
		    @media screen and (max-height: 899px) {
				top: 15px;
				right: 15px;
			}
		    @media screen and (max-width: 1199.9px) {
				top: 15px;
				right: 15px;
			}
		}
	}
}
@media (min-width: 576px) {
	.modal-dialog {
	    max-width: 90%;
	}
}
