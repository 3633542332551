input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  	color: #7986ae;
}
input::-moz-placeholder { /* Firefox 19+ */
  	color: #7986ae;
}
input:-ms-input-placeholder { /* IE 10+ */
  	color: #7986ae;
}
input:-moz-placeholder { /* Firefox 18- */
  	color: #7986ae;
}
.form-group {
	margin-bottom: 30px;
	@media screen and (max-width: 767px) {
		margin-bottom: 15px;
	}
}
.form-control {
	height: 90px;
	width: 100%;
	font-family: 'Roboto', sans-serif;
	font-weight: normal;
	font-style: normal;
	font-size: 18px;
	line-height: 30px;
	letter-spacing: 1px;
	color: #7986ae;
	min-height: auto;
	padding: 20px 35px;
	background: #14153b;
	border: 1px solid #595b92;
	border-radius: 50px;
	text-align: center;
	@media screen and (max-height: 899px) {
		height: 60px;
		font-size: 14px;
		line-height: 24px;
		padding: 10px 25px;
	}
	@media screen and (max-width: 1199.9px) {
		height: 60px;
		font-size: 14px;
		line-height: 24px;
		padding: 10px 25px;
	}
	&:hover {
		color: #fff;
		background: #1e2145;
		border: 2px solid #fff;
		&::-webkit-input-placeholder { 
		  	color: #fff;
		}
		&::-moz-placeholder {
		  	color: #fff;
		}
		&:-ms-input-placeholder { 
		  	color: #fff;
		}
		&:-moz-placeholder { 
		  	color: #fff;
		}
	}
	/*&:focus {
		color: #fff;
		background: #1e2145;
		border: 2px solid #fff;
		&::-webkit-input-placeholder { 
		  	color: #fff;
		}
		&::-moz-placeholder { 
		  	color: #fff;
		}
		&:-ms-input-placeholder { 
		  	color: #fff;
		}
		&:-moz-placeholder {
		  	color: #fff;
		}
	}*/
	&:not(:placeholder-shown) {
		color: #fff;
		background: #1e2145;
		border: 2px solid #fff;
		&::-webkit-input-placeholder {
		  	color: #fff;
		}
		&::-moz-placeholder { 
		  	color: #fff;
		}
		&:-ms-input-placeholder { 
		  	color: #fff;
		}
		&:-moz-placeholder { 
		  	color: #fff;
		}
	}
}

