@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
    font-family: 'Graphik LCG';
    src: url('../fonts/GraphikLCG-Semibold.eot');
    src: local('Graphik LCG Semibold'), local('GraphikLCG-Semibold'),
        url('../fonts/GraphikLCG-Semibold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GraphikLCG-Semibold.woff2') format('woff2'),
        url('../fonts/GraphikLCG-Semibold.woff') format('woff'),
        url('../fonts/GraphikLCG-Semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik LCG';
    src: url('../fonts/GraphikLCG-SemiboldItalic.eot');
    src: local('Graphik LCG Semibold Italic'), local('GraphikLCG-SemiboldItalic'),
        url('../fonts/GraphikLCG-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GraphikLCG-SemiboldItalic.woff2') format('woff2'),
        url('../fonts/GraphikLCG-SemiboldItalic.woff') format('woff'),
        url('../fonts/GraphikLCG-SemiboldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Graphik LCG';
    src: url('../fonts/GraphikLCG-Regular.eot');
    src: local('Graphik LCG Regular'), local('GraphikLCG-Regular'),
        url('../fonts/GraphikLCG-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GraphikLCG-Regular.woff2') format('woff2'),
        url('../fonts/GraphikLCG-Regular.woff') format('woff'),
        url('../fonts/GraphikLCG-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik LCG';
    src: url('../fonts/GraphikLCG-RegularItalic.eot');
    src: local('Graphik LCG Regular Italic'), local('GraphikLCG-RegularItalic'),
        url('../fonts/GraphikLCG-RegularItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GraphikLCG-RegularItalic.woff2') format('woff2'),
        url('../fonts/GraphikLCG-RegularItalic.woff') format('woff'),
        url('../fonts/GraphikLCG-RegularItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
@font-face {
    font-family: 'Graphik LCG';
    src: url('../fonts/GraphikLCG-Bold.eot');
    src: local('Graphik LCG Bold'), local('GraphikLCG-Bold'),
        url('../fonts/GraphikLCG-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GraphikLCG-Bold.woff2') format('woff2'),
        url('../fonts/GraphikLCG-Bold.woff') format('woff'),
        url('../fonts/GraphikLCG-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Graphik LCG';
    src: url('../fonts/GraphikLCG-BoldItalic.eot');
    src: local('Graphik LCG Bold Italic'), local('GraphikLCG-BoldItalic'),
        url('../fonts/GraphikLCG-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/GraphikLCG-BoldItalic.woff2') format('woff2'),
        url('../fonts/GraphikLCG-BoldItalic.woff') format('woff'),
        url('../fonts/GraphikLCG-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}