html {
    height: 100%;
    overflow-x:hidden;
    @media screen and (max-width: 767px) {
        width: 100%;   
        overflow-x: hidden;
        overflow-y: auto;
    }    
}
body {
    width: 100%;
    min-height: 100%;
    margin: 0px; 
    padding: 0px; 
    overflow-x: hidden;
    overflow-y: auto; 
    position: relative;  
    display: flex;
    flex-direction: column;
    align-items: stretch; 
    color: $main;
    font-family: 'Roboto', sans-serif;
    background-color: $bg-color;
    background-image: url('../images/body-bg.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;    
}
main {
    flex-grow: 1; 
}
header, main, footer {
    flex-shrink: 0; 
}
@media (min-width: 1680px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1510px;
    }
}