body > canvas {
    opacity: .1;
}
.content_slide {
	width: 100%;
    min-height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    transition: .4s ease;
}
#fp-nav ul li, 
.fp-slidesNav ul li {
    margin: 40px 7px;
}
#fp-nav ul li a span, 
.fp-slidesNav ul li a span {
    background: #fff;
}
#fp-nav ul li a.active span, 
#fp-nav ul li:hover a.active span, 
.fp-slidesNav ul li a.active span, 
.fp-slidesNav ul li:hover a.active span {
    background: $brand;
}
.section {
	h2 {
		text-transform: uppercase;
		margin-bottom: 30px;
		transition: 1s ease;
		@media screen and(min-width: 992px) {
			transform: translate(0, -300%);			
		}
	}
	h3 {
		transition: 1s ease;
		@media screen and(min-width: 992px) {
			transform: translate(0, -200%);			
		}
	}
	&.active {
		@media screen and(min-width: 992px) {
			h2 {
				transform: translate(0, 0);
			}
			h3 {
				transform: translate(0, 0);
			}
			.about-block {
				.text-column {		
					p {
						transform: translate(0, 0);
					}
				}
			}
			.advertisers-img-wrapper {
				transform: translate(0, 0);
			}
			.advertisers-info-block {
				p {
					transform: translate(0, 0);
				}
				.btn {
					transform: translate(0, 0);
				}
			}
			.advantages-block {
				transform: translate(0, 0);
			}
			.youtube-img-parallax {
				transform: translate(0, 0);
			}
			.twitch-img-parallax {
				transform: translate(0, 0);
			}
			.blogers-img-wrapper {
				transform: translate(0, 0);
			}
			.blogers-info-block {
				p {
					transform: translate(0, 0);
				}
				.btn {
					transform: translate(0, 0);
				}
			}		
		}		
	}
}
#section0 {
	height: 100%;
	position: relative;
	justify-content: center;
	align-items: center;
	#scene {
		display: flex;
	    flex-direction: column;
	    justify-content: flex-start;
	    align-items: center;
	    width: 100%;
	    height: 100%;
	    min-height: 100%;
	    z-index: 1;
	    overflow: hidden;
	}	
	.banner {
		padding-top: 175px;
		text-align: center;
		position: relative;
		z-index: 2;
	    will-change: transform;
		img{
			margin-bottom: 20px;
		}
		h1 {
			margin-bottom: 20px;			
	    	text-transform: uppercase;
	    	position: relative;
	    	display: flex;
	    	align-items: center;
	    	justify-content: center;
	    	min-height: 200px;
	    	span {
	    		display: block;
	    		font-size: 150px;
		    	line-height: 115px;
		    	text-transform: uppercase;
		    	position: absolute;
		    	width: 774px;
		    	left: calc(50% - 387px);
				top: 42px;
		    	color: transparent;
		    	-webkit-text-fill-color: transparent;
				-webkit-text-stroke-color: #595b92;
				-webkit-text-stroke-width: 1.00px; 
				z-index: -1;
	    	}
		}
		p {
			margin-bottom: 80px;
			font-size: 25px;
	    	line-height: 50px;
	    	text-transform: capitalize;
	    }
	}
	.mercury-planet {
    	position: absolute;
	    width: 100%;
	    height: 100%;
	    background: url(../images/mercury1.png);
	    background-size: 100%;
	    background-repeat: no-repeat;
	    background-position: center bottom;
	    bottom: 0;
	    margin-top: 5%;
	    margin-left: 2%;
	    z-index: 1;
	    will-change: transform;
    }
    .banner-bg {
    	position: absolute;
	    width: 120%;
    	height: 100%;
    	margin-left: -10%;
    	margin-top: -5%;
	    background: url(../images/banner-bg.png);
	    background-size: cover;
	    background-position: center;
	    z-index: -1;
    	will-change: transform;
    	@media screen and (min-width: 767px) {
    		height: 120%;
    	}
    }
    .mouse {
    	position: absolute;
    	bottom: 0;
    	left: auto;
    	right: auto;
    	z-index: 1;
    }
}
#section1 {
	padding: 155px 0;	
	h2 {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		span {
			display: block;
    		font-size: 88px;
	    	line-height: 115px;
	    	text-transform: uppercase;
	    	color: transparent;
	    	-webkit-text-fill-color: transparent;
			-webkit-text-stroke-color: rgba(255,255,255,.2);
			-webkit-text-stroke-width: 1.00px;
		}
	}
}
#sub-section1 {
	padding: 155px 0;	
	h2 {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		span {
			display: block;
    		font-size: 88px;
	    	line-height: 115px;
	    	text-transform: uppercase;
	    	color: transparent;
	    	-webkit-text-fill-color: transparent;
			-webkit-text-stroke-color: rgba(255,255,255,.2);
			-webkit-text-stroke-width: 1.00px;
		}
	}
}
#section2 {
	padding: 155px 0;
	position: relative;
	overflow-x: hidden;
}
#section3 {
	padding: 155px 0;
	position: relative;
	overflow-x: hidden;
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		pointer-events: none;
		z-index: 0;
		width: 100%;
		height: 100%;
		background: url(../images/advantages-before.png) no-repeat left -160%;
		background-size: 318px auto;
	}
	.block-title {
		width: 100%;
		display: flex;
		align-items: center;
		margin-bottom: 60px;
		h3 {
			padding-left: 30px;
		}
	}
}
#sub-section3 {
	padding: 155px 0;
	position: relative;
	overflow-x: hidden;
	background: url(../images/advantages-bg.png) no-repeat center;
	background-size: 100% auto;
}
#section4 {
	padding: 155px 0;
	position: relative;
	overflow-x: hidden;
}
#section5 {
	padding: 155px 0 0;
	position: relative;
	overflow-x: hidden;
	h2 {
		margin-bottom: 40px;
	}
}
.about-block {
	width: 100%;
	display: grid;
	grid-template-columns: 45% 55%;
	margin-bottom: 100px;	
	.text-column {
		padding-right: 50px;		
		p {			
    		transition: 1s ease;
			&:not(:last-child) {
				margin-bottom: 50px;
			}
			@media screen and(min-width: 992px) {
				transform: translate(0, 100%);			
			}			
		}
	}
}
.services-block {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: 20px;
	.single-service {
		border: 1px solid rgba(89, 91, 146, .25);
		border-radius: 6px;
		background: #070a32;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		padding: 30px 35px 35px;
		text-align: center;
		position: relative;
		height: 350px;
		transition: 1s ease;
		//transition: all .25s ease-in-out;
		img {
			height: 155px;
			width: auto;
			max-width: 100%;
			object-fit: contain;
			object-position: top; 
			margin-bottom: 40px;
			transition: all .25s ease-in-out;
		}
		.icon {
			height: 155px;
			width: auto;
			max-width: 100%;			
			margin-bottom: 40px;
			fill: #fff;
			transition: all .25s ease-in-out;
		}
		p {
			font-family: 'Graphik LCG';
			font-weight: bold;
			font-style: normal;
			letter-spacing: 1px;
			color: #fff;
			margin-bottom: 0;
			min-height: 80px;
			display: flex;
			align-items: center;
			justify-content: center;
			transition: all .25s ease-in-out;
		}
		.hidden-block {
			position: absolute;
			bottom: -25px;
			right: -35px;			
			transform: scale(0, 0);
			z-index: 1;
			opacity: 0;
			pointer-events: none;
			padding: 20px 10px 30px 30px;
			border-radius: 6px;
			width: 150%;
			height: auto;
			background: #12153b;
			border: 1px solid rgba(89, 91, 146, .25);
			box-shadow: 0 3px 53px rgba(0,0,0,.15);
			text-align: left;
			transition: all .25s ease-in-out;
			h5 {
				font-family: 'Graphik LCG';
				font-weight: bold;
				font-style: normal;
				font-size: 22px;
				line-height: 52px;
				letter-spacing: 1.48px;
				color: #fff;
				margin-bottom: 5px;
				transition: all .25s ease-in-out;
			}
			p {
				font-family: 'Roboto', sans-serif;
				font-weight: normal;
				font-style: normal;
				font-size: 16px;
				line-height: 30px;
				letter-spacing: 1px;
				color: #7986ae;
				min-height: auto;
    			justify-content: flex-start;
				transition: all .25s ease-in-out;
			}
		}
		&:hover {
			transform: scale(1.4, 1.4);
			padding: 30px 15px 35px;
			background: #1e2145;
			z-index: 2;
			box-shadow: 0 0 90px rgba(255,255,255,.25);
			.icon {
				fill: url(#service-hover-gradient) #fff;
				-webkit-filter: drop-shadow( 0px 0px 20px rgba(218, 68, 0, .91));
  				filter: drop-shadow( 0px 0px 20px rgba(218, 68, 0, .91));
			}
			p {
				font-size: 0;
				line-height: 0;
				letter-spacing: 0;
			}
			.hidden-block {
				transform: scale(1, 1);
				z-index: 1;
				opacity: 1;
				p {
					font-size: 16px;
					line-height: 30px;
					letter-spacing: 0;
				}
			}
		}
		&:first-child {
			.hidden-block {
				right: auto;
				left: -35px;	
			}
		}
	}
}
.brand-name {
	display: block;
	font-family: 'Graphik LCG';
	font-weight: bold;
	font-style: normal;
	font-size: 88px;
	line-height: 115px;
	text-transform: uppercase;
	color: transparent;
	-webkit-text-fill-color: transparent;
	-webkit-text-stroke-color: rgba(255,255,255,.08);
	-webkit-text-stroke-width: 1.00px;
	position: absolute;
	left: -188px;
    bottom: calc(50% - 58px);
	transform: rotate(-90deg);
}
.advertisers-img-wrapper {
	position: absolute;
	bottom: calc(50% - 45vh);
	right: -6%;
	width: 50%;
	transition: 1s ease;
	@media screen and(min-width: 992px) {
		transform: translate(0, 40%);			
	}
}
.advertisers-info-block {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1;
	h2 {

	}
	h3 {
		margin-bottom: 50px;
		text-transform: uppercase;
	}
	p {
		font-weight: 700;
		margin-bottom: 70px;
		transition: 1s ease;
		@media screen and(min-width: 992px) {
			transform: translate(0, -100%);			
		}
	}
	.btn {
		transition: 1s ease;
		@media screen and(min-width: 992px) {
			transform: translate(0, -100%);			
		}
	}
}
.advantages-block {
	width: 100%;
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-row-gap: 50px;
	grid-column-gap: 50px;
	transition: 1s ease;
	@media screen and(min-width: 992px) {
		transform: translate(0, -100%);			
	}
	.single-advantage {
		svg {
			margin-bottom: 45px;
			height: 85px;
			width: auto;
			fill: #fff;
		}
		p {
			font-family: 'Roboto', sans-serif;
			font-weight: 700;
			font-style: normal;
			font-size: 18px;
			line-height: 30px;	
			margin: 0;	
		}
	}
}
.advantages-banners {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	margin-top: 100px;
	position: relative;
	.youtube-img-parallax {
		margin-left: 30px;
		width: 260px;
		transition: 1s ease;
		@media screen and(min-width: 992px) {
			transform: translate(0, -200%);			
		}
		img {
			max-width: 100%;
		}
	}
	.twitch-img-parallax {
		margin-right: 30px;
		width: 240px;
		transition: 1s ease;
		@media screen and(min-width: 992px) {
			transform: translate(0, -200%);			
		}
		img {
			max-width: 100%;
		}
	}
	.advantages-content {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
		.advantages-banner-text {
			padding: 0 15px;
			h3 {
				font-size: 48px;
				margin-bottom: 30px;
				text-transform: uppercase;
			}
			.number {
				font-family: 'Graphik LCG';
				font-weight: bold;
				font-style: normal;
				font-size: 70px;
				line-height: 97px;
				color: #fff;
				margin-bottom: 30px;
				text-transform: uppercase;
			}
			p {
				font-family: 'Graphik LCG';
				font-weight: bold;
				font-style: normal;
				font-size: 16px;
				line-height: 28px;
				color: #fff;
				margin-bottom: 0;
				text-transform: uppercase;
			}
		}
	}	
}
.blogers-img-wrapper {
	position: absolute;
	bottom: calc(50% - 45vh);
	left: 0;
	width: 50%;
	transition: 1s ease;
	@media screen and(min-width: 992px) {
		transform: translate(0, 40%);			
	}
}
.blogers-info-block {
	h2 {
		margin-bottom: 60px;
	}
	h3 {
		text-transform: uppercase;
		margin-bottom: 60px;
	}
	p {
		font-weight: 700;
		line-height: 30px;
		margin-bottom: 40px;
		max-width: 640px;
		transition: 1s ease;
		@media screen and(min-width: 992px) {
			transform: translate(0, 80%);			
		}
	}
	.btn {
		margin-top: 40px;
		transition: 1s ease;
		@media screen and(min-width: 992px) {
			transform: translate(0, 80%);			
		}
	}
}
.map-wrapper {
	width: 100%;
	position: relative;
	.map-block {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		iframe {
			width: 100%;
			height: 100%;
			margin-bottom: -15px;
		}
	}
	.contacts-wrapper {
		width: 490px;
		margin: 30px 0 30px auto;
		background: #020730;
		border: 1px solid #595b92;
		text-align: center;
		padding: 40px 35px;
		position: relative;
		z-index: 2;
		.contacts-block-title {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			margin-bottom: 50px;
			position: relative;
			img {
				height: 80px;
				width: auto;
				margin: 0 auto 20px;
			}
			h3 {
				font-size: 48px;
				line-height: 65px;
				text-transform: uppercase;
			}
			span {
				display: block;
				font-family: 'Graphik LCG';
				font-weight: bold;
				font-style: normal;
				font-size: 80px;
				line-height: 110px;
				text-transform: uppercase;
				color: transparent;
				-webkit-text-fill-color: transparent;
				-webkit-text-stroke-color: rgba(255,255,255,.1);
				-webkit-text-stroke-width: 1.00px;
				position: absolute;
				left: 0;
			    bottom: -13px;
			    width: 100%;
			}
		}
		.contacts-blocks {
			margin-bottom: 60px;
			.single-contact-block {
				margin-bottom: 15px;
				display: flex;
				align-items: flex-start;
				flex-wrap: wrap;
				svg {
					width: 35px;
					height: 35px;
					fill: #fff;
					margin-right: 20px;
				}
				p {
					width: calc(100% - 55px);
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: flex-start;
					text-align: left;
					span {
						font-family: 'Roboto', sans-serif;
						font-weight: normal;
						font-style: normal;
						font-size: 20px;
						line-height: 40px;
					}
				}
				ul {
					width: 100%;
					padding: 0;
					margin: 15px 0 0;
					list-style: none;
					display: flex;
					align-items: center;
					justify-content: center;
					li {
						margin: 0 10px;
						a {
							svg {
								fill: #fff;
								width: 50px;
								height: 50px;
							}
						}
					}
				}
			}
		}
		.email-block {
			margin-bottom: 30px;
			svg {
				fill: #fff;
				width: 50px;
				height: 35px;
				margin-bottom: 15px;
			}
			p {
				font-family: 'Roboto', sans-serif;
				font-weight: 700;
				font-style: normal;
				font-size: 20px;
				line-height: 22px;
			}
			a {
				font-family: 'Roboto', sans-serif;
				font-weight: normal;
				font-style: normal;
				font-size: 20px;
				line-height: 22px;
				color: #fff;
				text-decoration: none;
				&:hover {
					color: $brand;
				}
			}
		}
		.user-block {
			svg {
				fill: #fff;
				width: 40px;
				height: 40px;
				margin-bottom: 15px;
			}
			p {
				font-family: 'Roboto', sans-serif;
				font-weight: 700;
				font-style: normal;
				font-size: 20px;
				line-height: 22px;
			}
			a {
				font-family: 'Roboto', sans-serif;
				font-weight: normal;
				font-style: normal;
				font-size: 20px;
				line-height: 22px;
				color: #fff;
				text-decoration: none;
				&:hover {
					color: $brand;
				}
			}
		}
	}
}
.fp-warning, .fp-watermark {
	display: none;
}

/* Responsive */
@media screen and (min-width: 767px) {
	.fp-enabled {
		.content_slide {
	    	opacity: 0;
	    	&.active {
			    opacity: 1;
			}
	    }
	}
}

@media screen and (max-height: 899px) {
	#section0 {
		.banner {
	    	padding-top: 125px;
	    	img {
			    margin-bottom: 0;
			    max-height: 80px;
			}
			h1 {
				min-height: 120px;
				margin-bottom: 0;
				span {
				    font-size: 90px;
				    line-height: 90px;
				    top: 15px;
				}
			}
			p {
			    font-size: 20px;
			    line-height: 30px;
			}			
	    }
	    .mouse {
		    max-width: 25px;
		}
		.mercury-planet {
		    background-size: 85%;
		}
	}
	#section1 {
		padding: 100px 0 50px;	
		h2 {
			span {
			    font-size: 45px;
			    line-height: 65px;
			}
		}
	}
	#sub-section1 {
		padding: 100px 0 50px;	
		h2 {
			span {
			    font-size: 45px;
			    line-height: 65px;
			}
		}
	}
	#section2 {
		padding: 100px 0 50px;	
	}
	#section3 {
		padding: 100px 0 50px;	
	}
	#sub-section3 {
		padding: 50px 0 50px;
	}
	#section4 {
		padding: 100px 0 50px;	
	}
	.about-block {
		margin-bottom: 50px;
		.text-column {
			p:not(:last-child) {
			    margin-bottom: 30px;
			}
		}
	}
	.services-block {
		.single-service {
			height: 270px;
			.icon {
			    height: 100px;
			    margin-bottom: 20px;
			}
			&:hover {
				p {
				    font-size: 16px;
				    line-height: 30px;
				    letter-spacing: 1.48px;
				}
				.hidden-block {
					h5 {
					    font-size: 18px;
					    line-height: 35px;
					}
					p {
					    font-size: 14px;
					    line-height: 25px;
					}
				}
			}
			.hidden-block {
				padding: 20px;
				h5 {
				    font-size: 18px;
				    line-height: 35px;
				}
				p {
				    font-size: 14px;
				    line-height: 25px;
				}
			}
		}
	}
	.advertisers-img-wrapper {
	    max-height: 90vh;
	    width: 50%;
	    #advertisers-scene {
	    	max-height: 90vh;
	    }
	    img {
	    	height: 90vh;
	    	max-height: 90vh;
	    	width: auto;
	    }
	}
	.advertisers-info-block {
		h3 {
		    margin-bottom: 30px;
		}
		p {
			margin-bottom: 40px;
		}
	}
	.brand-name {
	    font-size: 80px;
	    line-height: 100px;
	    left: -170px;
	    bottom: calc(50% - 40px);
	}
	.advantages-banners {
		margin-top: 60px;
		.advantages-content {
			.advantages-banner-text {
				h3 {
					font-size: 30px;
					line-height: 34px;
				}
				.number {
				    font-size: 50px;
				    line-height: 80px;
				}
			}
		}
	}
	.blogers-img-wrapper {
		max-height: 90vh;
	    width: 50%;
	    #blogers-scene {
	    	max-height: 90vh;
	    }
	    img {
	    	height: 90vh;
	    	max-height: 90vh;
	    	width: auto;
	    }
	}
	.blogers-info-block {
		h2 {
		    margin-bottom: 30px;
		}
		h3 {
		    margin-bottom: 30px;
		}
		p {
		    margin-bottom: 20px;
		}
		.btn {
		    margin-top: 0px;
		    margin-bottom: 40px;
		}
	}
}
@media screen and (max-height: 768px) {
	#section0 {
		.banner {
	    	padding-top: 125px;
	    	img {
			    margin-bottom: 0;
			    max-height: 100px;
			}
			h1 {
				min-height: 120px;
				margin-bottom: 15px;
				font-size: 70px;
				span {
				    font-size: 120px;
				    line-height: 90px;
				    top: 15px;
				}
			}
			p {
			    font-size: 24px;
			    line-height: 30px;
			}			
	    }
		.mercury-planet {
		    background-size: 100%;
		}
	}
	.services-block {
		.single-service {
			.hidden-block {
			    bottom: 10px;
			}
		}
	}
}

@media screen and (max-width: 1499.9px) {
	.advantages-banners {
		.youtube-img-parallax {
		    margin-left: 20px;
		    width: 200px;
		    img {
		    	max-width: 100%;
		    }		    
		}
		.twitch-img-parallax {
			margin-right: 20px;
		    width: 200px;
		    img {
		    	max-width: 100%;
		    }
		}
	}
	.brand-name {
	    font-size: 50px;
	    line-height: 70px;
	    left: -105px;
	}
	.advantages-banners {
		.advantages-content {
			.advantages-banner-text {
				h3 {
					margin-bottom: 10px;
				}
				.number {
				    font-size: 40px;
				    line-height: 70px;
				}
			}
		}
	}
	.services-block {
		.single-service {
			padding: 20px 15px 25px;
			height: 250px;
			& > p {
				min-height: 60px;
			}
			.hidden-block {
				padding: 20px;
				bottom: 20px;
				right: 0;
			}
			&:first-child {
				.hidden-block {
				    right: auto;
				    left: 0px;
				}
			}
			&:hover {
			    transform: scale(1.2,1.2);
			    padding: 20px 15px 25px;
			}
		}
	}
}
@media screen and (max-width: 1380px) {
	.services-block {
		.single-service {
			.hidden-block {
				p {
				    font-size: 12px;
				    line-height: 20px;
				}
			}
			&:hover {
				.hidden-block {
					p {
					    font-size: 12px;
					    line-height: 20px;
					}
				}
			}
		}
	}
	.advantages-banners {
		.advantages-content {
			.advantages-banner-text {
				h3 {
					font-size: 26px;
    				line-height: 30px;
				}
				.number {
					font-size: 36px;
    				line-height: 66px;
				}
				p {
					font-size: 14px;
    				line-height: 24px;
				}
			}
		}
		.youtube-img-parallax {
		    margin-left: 10px;
		    width: 260px;
		}
		.twitch-img-parallax {
		    margin-right: 10px;
		    width: 240px;
		}
	}
	.advantages-block {
		.single-advantage {
			svg {
			    margin-bottom: 25px;
			    height: 65px;
			}
			p {
				font-size: 14px;
    			line-height: 24px;
			}
		}
	}
}
@media screen and (max-width: 1279.9px) {
	.services-block {
		.single-service {
			.hidden-block {
				p {
				    font-size: 12px;
				    line-height: 20px;
				}
			}
			&:hover {
				.hidden-block {
					p {
					    font-size: 12px;
					    line-height: 20px;
					}
				}
			}
		}
	}
	.advantages-banners {
		.advantages-content {
			.advantages-banner-text {
				h3 {
					font-size: 26px;
    				line-height: 30px;
				}
				.number {
					font-size: 36px;
    				line-height: 66px;
				}
				p {
					font-size: 14px;
    				line-height: 24px;
				}
			}
		}
		.youtube-img-parallax {
		    margin-left: 10px;
		    width: 150px;
		}
		.twitch-img-parallax {
		    margin-right: 10px;
		    width: 150px;
		}
	}
	.advantages-block {
		.single-advantage {
			svg {
			    margin-bottom: 25px;
			    height: 65px;
			}
			p {
				font-size: 14px;
    			line-height: 24px;
			}
		}
	}
}
@media screen and (max-width: 1199.9px) {
	.section {
		h2 {
    		margin-bottom: 20px;
    	}
    }
	#section0 {
		.banner {
		    padding-top: 0;
		}
		#scene {
		    justify-content: center;
		}
		.mouse {
		    max-width: 16px;
		    left: calc(50% - 8px);
    		right: calc(50% - 8px);
		}
	}
	.about-block {
		margin-bottom: 30px;
		.text-column {
			p {
				br {
					display: none;
				}
			}
		}
	}
	.services-block {
		.single-service {
			height: 180px;
			p {
				font-size: 12px;
				line-height: 20px;
				min-height: 40px;
			}
			.icon {
			    height: 70px;
			}
			.hidden-block {
			    right: -20px;
			    bottom: 0;
			    width: 180%;
			    h5 {
			    	font-size: 16px;
			    	line-height: 25px;
			    }
			    p {
			    	font-size: 10px;
			    	line-height: 20px;
			    }
			}
			&:first-child {
				.hidden-block {
				    right: auto;
				    left: -20px;
				}
			}
			&:hover {
				.hidden-block {
					h5 {
					    font-size: 15px;
					    line-height: 25px;
					}
					p {
				    	font-size: 10px;
				    	line-height: 20px;
				    }
				}
			}
		}
	}
	.advertisers-img-wrapper {
		max-height: 80vh;
		top: 20vh;
		#advertisers-scene {
		    max-height: 80vh;
		}
		img {
		    height: 60vh;
		    max-height: 60vh;
		    width: auto;
		}
	}
	.advantages-block {
	    grid-row-gap: 20px;
	    grid-column-gap: 30px;
	    .single-advantage {
	    	svg {
			    margin-bottom: 20px;
			    height: 60px;
			}
			p {
			    font-size: 12px;
			    line-height: 22px;
			}
		}
	}
	.advantages-banners {
	    margin-top: 50px;
	    .advantages-content {
	    	.advantages-banner-text {
	    		h3 {
				    font-size: 24px;
				    line-height: 28px;
				}
				.number {
				    font-size: 34px;
				    line-height: 60px;
				}
				p {
				    font-size: 10px;
    				line-height: 12px;
				}
			}
		}
	}
	.blogers-img-wrapper {
	    max-height: 80vh;
	    top: 20vh;
		#blogers-scene {
		    max-height: 80vh;
		}
		img {
		    height: 60vh;
		    max-height: 60vh;
		    width: auto;
		}
	}
	.blogers-info-block {
		p {
		    font-size: 14px;
		    line-height: 24px;
		    br {
		    	display: none;
		    }
		}
	}
	.map-wrapper {
		.contacts-wrapper {
		    margin: 20px 0 20px auto;
		    padding: 20px 25px;
		    width: 370px;
		    .contacts-block-title {
			    margin-bottom: 20px;
			     img {
				    height: 60px;
				    margin: 0 auto 10px;
				}
				span {
				    font-size: 60px;
				    line-height: 70px;
				    bottom: 4px;
				}
			}
			.contacts-blocks {
			    margin-bottom: 40px;
			    .single-contact-block {
			    	svg {
					    width: 20px;
					    height: 20px;
					    margin-right: 15px;
					}
					p {
					    width: calc(100% - 35px);
					    span {
					    	font-size: 16px;
							line-height: 24px;
					    }
					}
					ul {
					    margin: 0px 0 0;
					    li {
					    	margin: 0 2px;
					    	a {
					    		svg {
								    width: 30px;
								    height: 30px;
								}
							}
						}
					}
				}
			}
			.email-block {
				svg {
				    width: 25px;
				    height: 25px;
				    margin-bottom: 5px;
				}
				p {
				    font-size: 16px;
				    line-height: 22px;
				    margin: 0;
				}
				a {
					font-size: 16px;
				    line-height: 22px;
				}
			}
			.user-block {
				svg {
				    width: 25px;
				    height: 25px;
				    margin-bottom: 5px;
				}
				p {
				    font-size: 16px;
				    line-height: 22px;
				    margin: 0;
				}
				a {
					font-size: 16px;
				    line-height: 22px;
				}
			}
		}
	}
	#section5 {
	    padding: 100px 0 0;
	    h2 {
		    margin-bottom: 30px;
		}
	}
	.brand-name {
	    bottom: 100px;
	}
}
@media screen and (max-width: 991.9px) {
	.section {
		//height: auto !important;
	}
	#section0 {
	    min-height: 100vh;
	    padding: 0;
	    justify-content: center;
	    overflow-x: hidden;
	    #scene {
	    	min-height: 100vh;
	    }
	    .banner-bg {
		    margin-left: 0;
		    margin-top: 0;
		}
		.mercury-planet {
			margin-left: 2%;
		    margin-top: 0;
		}
		.mouse {
		    max-width: 10px;
		    left: calc(50% - 5px);
    		right: calc(50% - 5px);
		}
	    .banner {
	    	img {
			    margin-bottom: 0;
			    max-height: 80px;
			}
	    	h1 {
			    min-height: auto;
			    margin-bottom: 15px;
			    font-size: 46px;
			    span {
				    font-size: 65px;
				    line-height: 65px;
				    top: 11px;
				}
			}
			p {
			    font-size: 20px;
			}
		}
	}
	#section1 {
	    padding: 100px 0 50px;
	}
	#sub-section1 {
	    padding: 80px 0 50px;
	    h2 {
	    	span {
	    		font-size: 50px;
	    	}
	    }
	}
	.about-block {
	    grid-template-columns: 100%;
	    margin-bottom: 0;
	}
	.services-block {
		grid-template-columns: repeat(2,1fr);
		grid-row-gap: 30px;
		.single-service {
			.hidden-block {
			    right: 0;
			}
			&:first-child {
				.hidden-block {
				    right: auto;
				    left: 0;
				}
			}
			&:nth-child(3) {
				.hidden-block {
				    right: auto;
				    left: 0;
				}
			}
		}
	}
	.brand-name {
	    display: none;
	}
	#section2 {
	    padding: 80px 0 50px;
	    overflow-x: hidden;
	    //flex-wrap: wrap;
	}
	.advertisers-img-wrapper {
	    max-height: none;
	    top: auto;
	    left: 0;
	    bottom: auto;
	    position: relative;
	    width: 100%;
	    #advertisers-scene {
		    max-height: none;
		}
	    img {
		    height: auto;
		    max-height: 100%;
		    width: 100%;
		    object-fit: contain;
    		object-position: center;
		    &:last-child {
		    	position: absolute;
		    	top: 0;
		    	left: 0;
		    }
		}
	}
	#section3 {
	    padding: 80px 0 50px;
	    flex-wrap: wrap;
	    .block-title {
		    flex-wrap: wrap;
		    h3 {
			    padding-left: 0;
			    width: 100%;
			}
		}
	}
	#sub-section3 {
	    padding: 50px 0 50px;
	    flex-wrap: wrap;
	    background-size: 100% 100%;	    
	}
	.advantages-block {
    	grid-template-columns: repeat(2,1fr);
    }
    .youtube-img-parallax {
    	display: none;
    }
    .twitch-img-parallax {
    	display: none;
    }
    .advantages-banners {
    	.advantages-content {
		    flex-wrap: wrap;
		    .advantages-banner-text {
			    padding: 0 0 0 150px;
			    width: 100%;
			    margin-bottom: 40px;
			    position: relative;	
			    h3 {
				    margin-bottom: 0px;
				}		    
			    &:first-child {
			    	&:before {
			    		content: '';
				    	width: 150px;
				    	height: 110px;
				    	position: absolute;
				    	top: auto;
				    	left: 0;
				    	background: url(../images/youtube.png) no-repeat center;
				    	background-size: auto 100%;
			    	}
			    	
			    }
			    &:last-child {
			    	&:before {
			    		content: '';
				    	width: 150px;
				    	height: 110px;
				    	position: absolute;
				    	top: auto;
				    	left: 0;
				    	background: url(../images/twitch.png) no-repeat center;
				    	background-size: auto 100%;
			    	}
			    }
			}
		}
	}
	#section4 {
	    padding: 80px 0 50px;
	    overflow-x: hidden;
	}
	.blogers-img-wrapper {
	    max-height: none;
	    top: auto;
	    left: 0;
	    bottom: auto;
	    position: relative;
	    width: 100%;
	    z-index: -1;
	    #blogers-scene {
		    max-height: none;
		}
	    img {
		    height: auto;
		    max-height: 100%;
		    width: 100%;
		    object-fit: contain;
    		object-position: center;
		    &:last-child {
		    	position: absolute;
		    	top: 0;
		    	left: 0;
		    }
		}
	}
	#section5 {
	    padding: 80px 0 0;
	    flex-wrap: wrap;
	    overflow-x: hidden;
	}
	.map-wrapper {
		.map-block {
			position: relative;
		    top: auto;
		    left: auto;
		    height: 300px;
		}
		.contacts-wrapper {
		    margin: 0;
		    padding: 20px 25px;
		    width: 100%;
		}
	}
}
@media screen and (max-width: 575.9px) {
	#sub-section1 {
		h2 {
			span {
	    		font-size: 40px;
	    	}
	    }
	}
	.advantages-banners .advantages-content .advantages-banner-text .number {
	    font-size: 30px;
	    line-height: 60px;
	}
	.header-wrapper .logo-block .logo img {
	    max-width: 125px;
	}
}
@media screen and (max-width: 479.9px) {
	#section0 .banner h1 span {
	    font-size: 60px;
	    line-height: 60px;
	}
	.about-block .text-column {
	    padding-right: 0;
	}
	#sub-section1 h2 span {
	    font-size: 40px;
	    display: none;
	}
	.services-block {
	    grid-template-columns: repeat(1,1fr);
	    grid-column-gap: 0px;
	}
	.services-block .single-service:hover {
		transform: scale(1, 1);
	}
	.services-block .single-service .hidden-block {
	    right: 0;
	    left: 0;
	    max-width: 300px;
	    width: 100%;
	    padding: 10px;
	    text-align: center;
	}
	.services-block .single-service:first-child .hidden-block {
	    right: 0;
    	left: 0;    
	}
	.services-block .single-service:nth-child(3) .hidden-block {
	    right: 0;
    	left: 0;    
	}
	.advantages-block {
	    grid-template-columns: repeat(1,1fr);
	}
	.advantages-banners {
	    margin-top: 20px;
	}
	.advantages-banners .advantages-content .advantages-banner-text {
	    padding: 120px 0 0;
    	text-align: center;
	}
	.advantages-banners .advantages-content .advantages-banner-text:first-child:before {
	    top: 0;
	    left: 0;
	    right: 0;
	    width: 100%;
	}
	.advantages-banners .advantages-content .advantages-banner-text:last-child:before {
	    top: 0;
	    left: 0;
	    right: 0;
	    width: 100%;
	}
	.modal .modal-content .modal-body h2 {
	    font-size: 28px;
	}
	.modal .modal-content .modal-body .sub-title {
	    font-size: 34px;
	}
	.map-wrapper .contacts-wrapper .contacts-block-title span {
	    font-size: 50px;
	    line-height: 70px;
	    bottom: 4px;
	}
	.map-wrapper .contacts-wrapper .contacts-block-title h3 {
	    font-size: 35px;
	    line-height: 45px;
	    text-transform: uppercase;
	}
	#section3 .block-title {
	    text-align: center;
	    justify-content: center;
	}
	.advantages-block .single-advantage {
	    text-align: center;
	    margin-bottom: 20px;
	}
}
