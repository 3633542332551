.header-wrapper {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background: transparent;
	box-shadow: none;
	padding: 0;
	z-index: 10;
	transition: all .25s ease-in-out;
	padding: 10px 0 10px;
	@media screen and (min-width: 1200px) {
		padding: 40px 0 10px;
	}
	@media screen and (max-height: 899px) {
		padding: 10px 0 10px;
	}
	a {
		transition: all .25s ease-in-out;
		color: $main;
		&:hover {
			color: $main;
		}
	}	
	.header__content {
		position: relative;
	    display: flex;
	    justify-content: space-between;
	    align-items: center;
	    z-index: 2;	    
	}
	.logo-block {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		a {
			text-decoration: none;
		}
		.logo {
			img {
				width: 100%;
				max-width: 289px;
				height: auto;
				@media screen and (max-height: 899px) {
					max-width: 235px;
				}
				@media screen and (max-width: 1199.9px) {
					max-width: 170px;
				}
			}
		}	
	}	
	.header__bars {
		.bars {
		    position: relative;
		    width: 15px;
		    height: 2px;
		    border-radius: 5px;
		    background: #fff;
		    transition: .2s ease;
		    &:before, &:after {
			    position: absolute;
			    display: block;
			    content: '';
			    width: 15px;
			    height: 2px;
			    border-radius: 5px;
			    background: #fff;
			    transition: .2s ease;
			}
			&:before {
				top: -5px;
			}
			&:after {
			    bottom: -5px;
			}
			&.active {
			    background: transparent;
			    &:before {
				    transform: rotate(45deg);
				    top: 0;
				}
				&:after {
				    transform: rotate(-45deg);
				    bottom: 0;
				}
			}
			@media (min-width: 767px) {
			    width: 25px;
			    height: 4px;
			    &:before, &:after {
			    	width: 25px;
			    	height: 4px;
			    }
			    &:before {
					top: -9px;
				}
				&:after {
				    bottom: -9px;
				}
			}
		}
		@media (min-width: 1200px) {
	    	display: none;
	    }
	}
	.navmenu {		
		padding: 130px 30px 30px;
	    position: fixed;
	    height: 100%;
	    width: 100%;
	    top: 0;
	    left: 0;
	    z-index: 1;
	    max-height: 0;
	    opacity: 0;
	    visibility: hidden;
	    transition: .2s ease;
		&.active {
		    max-height: 100%;
		    opacity: 1;
		    visibility: visible;
		    background: #0067FF;
		}
		@media screen and (min-width: 1200px) {
			position: relative;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			max-height: initial;
		    visibility: visible;
		    background: transparent;
		    padding: 0;
		    opacity: 1;
		}
	}
	.language-block {
		display: flex;
		align-items: center;
		justify-content: center;
		@media screen and (min-width: 1200px) {
			justify-content: flex-end;
		}
		.language-switch {
			border: 2px solid $main;
			border-radius: 30px;
			padding: 2px;
		}
		.switch-button {
		  	background: transparent;
		  	border-radius: 30px;
		  	overflow: hidden;
		  	width: 110px;
		  	text-align: center;
		  	font-family: 'Graphik LCG';
		  	font-weight: normal;
		  	font-size: 18px;
		  	letter-spacing: 1px;
		  	color: $main;
		  	position: relative;
		  	padding-right: 55px;
		  	text-transform: uppercase;
		  	&:before {
			    content: "ru";
			    position: absolute;
			    top: 0;
			    bottom: 0;
			    right: 0;
			    width: 55px;
			    display: flex;
			    align-items: center;
			    justify-content: center;
			    z-index: 3;
			    pointer-events: none;
			}

			&-checkbox {
			    cursor: pointer;
			    position: absolute;
			    top: 0;
			    left: 0;
			    bottom: 0;
			    width: 100%;
			    height: 100%;
			    opacity: 0;
			    z-index: 2;

			    &:checked + .switch-button-label:before {
			      	transform: translateX(55px);
			      	transition: transform 300ms linear;
			    }

			    & + .switch-button-label {
			      	position: relative;
			      	padding: 11px 0;
			      	display: block;
			      	user-select: none;
			      	pointer-events: none;

				    &:before {
				        content: "";
				        background: linear-gradient(to bottom, #ff9400, #ff3d00);
				        height: 100%;
				        width: 100%;
				        position: absolute;
				        left: 0;
				        top: 0;
				        border-radius: 30px;
				        transform: translateX(0);
				        transition: transform 300ms;
				    }

				    .switch-button-label-span {
				        position: relative;
				    }
	    		}
	  		}

	  		@media screen and (max-height: 899px) {
				font-size: 16px;
			}
		}
	}	
	.menu {
	    padding: 0;
	    margin: 0 0 40px;
	    list-style: none;
	    display: flex;
	    flex-direction: column;
	    align-items: center;
	    justify-content: flex-start;
        @media screen and (min-width: 1200px) {
        	margin: 0 35px;        	
		    flex-direction: row;
	        flex-wrap: wrap;
	        justify-content: flex-start;
	        align-items: center;
        }
        li {            
            margin: 0 0 25px; 
            &:last-child {
                margin: 0;
            }          
            a {
                display: flex;
                font-family: 'Graphik LCG';
                font-style: normal;
                font-weight: normal;
                font-size: 27px;
                line-height: 1.25;
                color: $main;
                text-decoration: none;
                transition: all .25s ease; 
                text-align: center;  
                position: relative;
                padding-bottom: 10px;
                margin: 0 0 25px; 
                &:last-child {
	                margin: 0;
	            }
                span {
                	color: $brand;
                	margin: 0 5px;
                }
                &:before {
                	content: '';
                	width: 0;
                	height: 5px;
                	background: linear-gradient(to right, #ff9400, #ff3d00);
                	position: absolute;
                	bottom: 0;
                	left: 0;
                	//transform: scaleX(0);
                	opacity: 0;
                	transition: all .25s ease; 
                	box-shadow: 0 0 16px rgba(255, 84, 0, .8);
                }             
                &:hover {
                    color: $main; 
                    text-decoration: none;
                    &:before {
                    	width: 100%;
                    	//transform: scaleX(1);
                    	opacity: 1;
                    }                
                }
                &.active {
	                -webkit-text-fill-color: #fff;
				    -webkit-text-stroke-color: #fff;
				    -webkit-text-stroke-width: 0.3px;
                    &:before {
                    	width: 100%;
                    	//transform: scaleX(1);
                    	opacity: 1;
                    } 
	            }  
            }            
            &.active {
                a {
                    -webkit-text-fill-color: #fff;
				    -webkit-text-stroke-color: #fff;
				    -webkit-text-stroke-width: 0.3px;
                    &:before {
                    	width: 100%;
                    	//transform: scaleX(1);
                    	opacity: 1;
                    } 
                }
            }  
            @media screen and (min-width: 1200px) {
            	margin: 0 35px 0 0; 
            	a {
	                display: flex;
	                font-family: 'Graphik LCG';
	                font-style: normal;
	                font-weight: normal;
	                font-size: 20px;
	                line-height: 1.25;
	                color: $main;
	                text-decoration: none;
	                transition: all .25s ease; 
	                text-align: center;  
	                position: relative;
	                padding-bottom: 0px;
	                span {
	                	color: $brand;
	                	margin: 0 5px;
	                }
	                &:before {
	                	content: '';
	                	width: 0;
	                	height: 5px;
	                	background: linear-gradient(to right, #ff9400, #ff3d00);
	                	position: absolute;
	                	top: -68px;
	                	bottom: auto;
	                	left: 0;
	                	//transform: scaleX(0);
	                	opacity: 0;
	                	transition: all .25s ease; 
	                	box-shadow: 0 0 16px rgba(255, 84, 0, .8);
	                }             
	                &:hover {
	                    color: $main; 
	                    text-decoration: none;
	                    &:before {
	                    	width: 100%;
	                    	//transform: scaleX(1);
	                    	opacity: 1;
	                    }                
	                }
	            }            
	            &.active {
	                a {
	                    -webkit-text-fill-color: #fff;
					    -webkit-text-stroke-color: #fff;
					    -webkit-text-stroke-width: 0.3px;
	                    &:before {
	                    	width: 100%;
	                    	//transform: scaleX(1);
	                    	opacity: 1;
	                    } 
	                }
            	} 
            }   
            @media screen and (max-height: 899px) {
				a {
	                &:before {
	                	top: -31px;
	                }  
	            }      
			} 
			@media screen and (max-width: 1199.9px) {
				a {
	                &:before {
	                	top: auto;
	                	bottom: -5px;
	                }  
	            }  
			}                 
        }
        a {
            display: flex;
            font-family: 'Graphik LCG';
            font-style: normal;
            font-weight: normal;
            font-size: 27px;
            line-height: 1.25;
            color: $main;
            text-decoration: none;
            transition: all .25s ease; 
            text-align: center;  
            position: relative;
            padding-bottom: 10px;
            margin: 0 0 25px; 
            &:last-child {
                margin: 0;
            }
            span {
            	color: $brand;
            	margin: 0 5px;
            }
            &:before {
            	content: '';
            	width: 0;
            	height: 5px;
            	background: linear-gradient(to right, #ff9400, #ff3d00);
            	position: absolute;
            	bottom: 0;
            	left: 0;
            	//transform: scaleX(0);
            	opacity: 0;
            	transition: all .25s ease; 
            	box-shadow: 0 0 16px rgba(255, 84, 0, .8);
            }             
            &:hover {
                color: $main; 
                text-decoration: none;
                &:before {
                	width: 100%;
                	//transform: scaleX(1);
                	opacity: 1;
                }                
            }
            &.active {
                -webkit-text-fill-color: #fff;
			    -webkit-text-stroke-color: #fff;
			    -webkit-text-stroke-width: 0.3px;
                &:before {
                	width: 100%;
                	//transform: scaleX(1);
                	opacity: 1;
                } 
            }  
        }
        @media screen and (min-width: 1200px) {        	
        	a {
                display: flex;
                font-family: 'Graphik LCG';
                font-style: normal;
                font-weight: normal;
                font-size: 20px;
                line-height: 1.25;
                color: $main;
                text-decoration: none;
                transition: all .25s ease; 
                text-align: center;  
                position: relative;
                padding-bottom: 0px;
                margin: 0 35px 0 0; 
                span {
                	color: $brand;
                	margin: 0 5px;
                }
                &:before {
                	content: '';
                	width: 0;
                	height: 5px;
                	background: linear-gradient(to right, #ff9400, #ff3d00);
                	position: absolute;
                	top: -68px;
                	bottom: auto;
                	left: 0;
                	//transform: scaleX(0);
                	opacity: 0;
                	transition: all .25s ease; 
                	box-shadow: 0 0 16px rgba(255, 84, 0, .8);
                }             
                &:hover {
                    color: $main; 
                    text-decoration: none;
                    &:before {
                    	width: 100%;
                    	//transform: scaleX(1);
                    	opacity: 1;
                    }                
                }
            }            
            &.active {
                a {
                    -webkit-text-fill-color: #fff;
				    -webkit-text-stroke-color: #fff;
				    -webkit-text-stroke-width: 0.3px;
                    &:before {
                    	width: 100%;
                    	//transform: scaleX(1);
                    	opacity: 1;
                    } 
                }
        	} 
        }   
        @media screen and (max-height: 899px) {
			a {
                &:before {
                	top: -31px;
                }  
            }      
		} 
		@media screen and (max-width: 1199.9px) {
			a {
                &:before {
                	top: auto;
                	bottom: -5px;
                }  
            }  
		} 
	}
}
@media screen and (max-height: 899px) {

}