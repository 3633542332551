/*
* Third Party
*/
@import "../../bower_components/normalize.css/normalize.css";
@import "../../bower_components/bootstrap/dist/css/bootstrap.css";
@import "../../bower_components/fullpage/dist/fullpage.css";
/*
 * Custom v1.0.0 
 */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@font-face {
  font-family: "Graphik LCG";
  src: url("../fonts/GraphikLCG-Semibold.eot");
  src: local("Graphik LCG Semibold"), local("GraphikLCG-Semibold"), url("../fonts/GraphikLCG-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/GraphikLCG-Semibold.woff2") format("woff2"), url("../fonts/GraphikLCG-Semibold.woff") format("woff"), url("../fonts/GraphikLCG-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Graphik LCG";
  src: url("../fonts/GraphikLCG-SemiboldItalic.eot");
  src: local("Graphik LCG Semibold Italic"), local("GraphikLCG-SemiboldItalic"), url("../fonts/GraphikLCG-SemiboldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/GraphikLCG-SemiboldItalic.woff2") format("woff2"), url("../fonts/GraphikLCG-SemiboldItalic.woff") format("woff"), url("../fonts/GraphikLCG-SemiboldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Graphik LCG";
  src: url("../fonts/GraphikLCG-Regular.eot");
  src: local("Graphik LCG Regular"), local("GraphikLCG-Regular"), url("../fonts/GraphikLCG-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/GraphikLCG-Regular.woff2") format("woff2"), url("../fonts/GraphikLCG-Regular.woff") format("woff"), url("../fonts/GraphikLCG-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Graphik LCG";
  src: url("../fonts/GraphikLCG-RegularItalic.eot");
  src: local("Graphik LCG Regular Italic"), local("GraphikLCG-RegularItalic"), url("../fonts/GraphikLCG-RegularItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/GraphikLCG-RegularItalic.woff2") format("woff2"), url("../fonts/GraphikLCG-RegularItalic.woff") format("woff"), url("../fonts/GraphikLCG-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Graphik LCG";
  src: url("../fonts/GraphikLCG-Bold.eot");
  src: local("Graphik LCG Bold"), local("GraphikLCG-Bold"), url("../fonts/GraphikLCG-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/GraphikLCG-Bold.woff2") format("woff2"), url("../fonts/GraphikLCG-Bold.woff") format("woff"), url("../fonts/GraphikLCG-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Graphik LCG";
  src: url("../fonts/GraphikLCG-BoldItalic.eot");
  src: local("Graphik LCG Bold Italic"), local("GraphikLCG-BoldItalic"), url("../fonts/GraphikLCG-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../fonts/GraphikLCG-BoldItalic.woff2") format("woff2"), url("../fonts/GraphikLCG-BoldItalic.woff") format("woff"), url("../fonts/GraphikLCG-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
html {
  height: 100%;
  overflow-x: hidden;
}
@media screen and (max-width: 767px) {
  html {
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

body {
  width: 100%;
  min-height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  color: #fff;
  font-family: "Roboto", sans-serif;
  background-color: #00022a;
  background-image: url("../images/body-bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
}

main {
  flex-grow: 1;
}

header, main, footer {
  flex-shrink: 0;
}

@media (min-width: 1680px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1510px;
  }
}
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  border: 2px solid transparent;
  min-height: 40px;
  padding: 20px 100px;
  font-family: "Graphik LCG";
  font-weight: bold;
  font-style: normal;
  font-size: 25px;
  line-height: 37px;
  text-align: center;
  outline: none;
}
@media screen and (max-height: 899px) {
  .btn {
    padding: 15px 70px;
    font-size: 20px;
    line-height: 30px;
  }
}
@media screen and (max-width: 1199.9px) {
  .btn {
    padding: 10px 50px;
    font-size: 16px;
    line-height: 26px;
  }
}

.btn-primary {
  color: #fff;
  background: linear-gradient(-90deg, #fb8d01 0%, #ee1b00 100%);
  box-shadow: 0 0 60px 30px rgba(218, 68, 0, 0.3);
  border: 2px solid transparent;
}
.btn-primary:hover {
  color: #fff;
  background: linear-gradient(-90deg, #fb8d01 0%, #ee1b00 100%);
  border: 2px solid transparent;
  box-shadow: none;
}
.btn-primary:active {
  color: #fff;
  background: linear-gradient(-90deg, #fb8d01 0%, #ee1b00 100%);
  border: 2px solid transparent;
  box-shadow: none;
}
.btn-primary:focus {
  color: #fff;
  background: linear-gradient(-90deg, #fb8d01 0%, #ee1b00 100%);
  border: 2px solid transparent;
  box-shadow: none;
}

input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #7986ae;
}

input::-moz-placeholder {
  /* Firefox 19+ */
  color: #7986ae;
}

input:-ms-input-placeholder {
  /* IE 10+ */
  color: #7986ae;
}

input:-moz-placeholder {
  /* Firefox 18- */
  color: #7986ae;
}

.form-group {
  margin-bottom: 30px;
}
@media screen and (max-width: 767px) {
  .form-group {
    margin-bottom: 15px;
  }
}

.form-control {
  height: 90px;
  width: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 1px;
  color: #7986ae;
  min-height: auto;
  padding: 20px 35px;
  background: #14153b;
  border: 1px solid #595b92;
  border-radius: 50px;
  text-align: center;
  /*&:focus {
  	color: #fff;
  	background: #1e2145;
  	border: 2px solid #fff;
  	&::-webkit-input-placeholder { 
  	  	color: #fff;
  	}
  	&::-moz-placeholder { 
  	  	color: #fff;
  	}
  	&:-ms-input-placeholder { 
  	  	color: #fff;
  	}
  	&:-moz-placeholder {
  	  	color: #fff;
  	}
  }*/
}
@media screen and (max-height: 899px) {
  .form-control {
    height: 60px;
    font-size: 14px;
    line-height: 24px;
    padding: 10px 25px;
  }
}
@media screen and (max-width: 1199.9px) {
  .form-control {
    height: 60px;
    font-size: 14px;
    line-height: 24px;
    padding: 10px 25px;
  }
}
.form-control:hover {
  color: #fff;
  background: #1e2145;
  border: 2px solid #fff;
}
.form-control:hover::-webkit-input-placeholder {
  color: #fff;
}
.form-control:hover::-moz-placeholder {
  color: #fff;
}
.form-control:hover:-ms-input-placeholder {
  color: #fff;
}
.form-control:hover:-moz-placeholder {
  color: #fff;
}
.form-control:not(:placeholder-shown) {
  color: #fff;
  background: #1e2145;
  border: 2px solid #fff;
}
.form-control:not(:placeholder-shown)::-webkit-input-placeholder {
  color: #fff;
}
.form-control:not(:placeholder-shown)::-moz-placeholder {
  color: #fff;
}
.form-control:not(:placeholder-shown):-ms-input-placeholder {
  color: #fff;
}
.form-control:not(:placeholder-shown):-moz-placeholder {
  color: #fff;
}

h1, .h1 {
  font-family: "Graphik LCG";
  font-weight: bold;
  font-style: normal;
  font-size: 87px;
  line-height: 115px;
}
@media screen and (max-height: 899px) {
  h1, .h1 {
    font-size: 60px;
    line-height: 90px;
  }
}
@media screen and (max-width: 991.9px) {
  h1, .h1 {
    font-size: 60px;
    line-height: 90px;
  }
}
h2, .h2 {
  font-family: "Graphik LCG";
  font-weight: bold;
  font-style: normal;
  font-size: 87px;
  line-height: 115px;
}
@media screen and (max-height: 899px) {
  h2, .h2 {
    font-size: 45px;
    line-height: 65px;
  }
}
@media screen and (max-width: 991.9px) {
  h2, .h2 {
    font-size: 45px;
    line-height: 65px;
  }
}
@media screen and (max-width: 575.9px) {
  h2, .h2 {
    font-size: 35px;
    line-height: 55px;
  }
}
@media screen and (max-width: 479.9px) {
  h2, .h2 {
    font-size: 25px;
    line-height: 45px;
  }
}

h3, .h3 {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 40px;
  line-height: 40px;
}
@media screen and (max-height: 899px) {
  h3, .h3 {
    font-size: 24px;
    line-height: 30px;
  }
}
@media screen and (max-width: 991.9px) {
  h3, .h3 {
    font-size: 24px;
    line-height: 30px;
  }
}
@media screen and (max-width: 575.9px) {
  h3, .h3 {
    font-size: 20px;
    line-height: 26px;
  }
}
@media screen and (max-width: 479.9px) {
  h3, .h3 {
    font-size: 18px;
    line-height: 24px;
  }
}

p {
  font-weight: normal;
  font-style: normal;
  font-size: 18px;
  line-height: 40px;
}
@media screen and (max-height: 899px) {
  p {
    font-size: 16px;
    line-height: 30px;
  }
}
@media screen and (max-width: 991.9px) {
  p {
    font-size: 16px;
    line-height: 30px;
  }
}
@media screen and (max-width: 575.9px) {
  p {
    font-size: 14px;
    line-height: 24px;
  }
}
@media screen and (max-width: 479.9px) {
  p {
    font-size: 12px;
    line-height: 22px;
  }
}

.header-wrapper {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  box-shadow: none;
  padding: 0;
  z-index: 10;
  transition: all 0.25s ease-in-out;
  padding: 10px 0 10px;
}
@media screen and (min-width: 1200px) {
  .header-wrapper {
    padding: 40px 0 10px;
  }
}
@media screen and (max-height: 899px) {
  .header-wrapper {
    padding: 10px 0 10px;
  }
}
.header-wrapper a {
  transition: all 0.25s ease-in-out;
  color: #fff;
}
.header-wrapper a:hover {
  color: #fff;
}
.header-wrapper .header__content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
}
.header-wrapper .logo-block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.header-wrapper .logo-block a {
  text-decoration: none;
}
.header-wrapper .logo-block .logo img {
  width: 100%;
  max-width: 289px;
  height: auto;
}
@media screen and (max-height: 899px) {
  .header-wrapper .logo-block .logo img {
    max-width: 235px;
  }
}
@media screen and (max-width: 1199.9px) {
  .header-wrapper .logo-block .logo img {
    max-width: 170px;
  }
}
.header-wrapper .header__bars .bars {
  position: relative;
  width: 15px;
  height: 2px;
  border-radius: 5px;
  background: #fff;
  transition: 0.2s ease;
}
.header-wrapper .header__bars .bars:before, .header-wrapper .header__bars .bars:after {
  position: absolute;
  display: block;
  content: "";
  width: 15px;
  height: 2px;
  border-radius: 5px;
  background: #fff;
  transition: 0.2s ease;
}
.header-wrapper .header__bars .bars:before {
  top: -5px;
}
.header-wrapper .header__bars .bars:after {
  bottom: -5px;
}
.header-wrapper .header__bars .bars.active {
  background: transparent;
}
.header-wrapper .header__bars .bars.active:before {
  transform: rotate(45deg);
  top: 0;
}
.header-wrapper .header__bars .bars.active:after {
  transform: rotate(-45deg);
  bottom: 0;
}
@media (min-width: 767px) {
  .header-wrapper .header__bars .bars {
    width: 25px;
    height: 4px;
  }
  .header-wrapper .header__bars .bars:before, .header-wrapper .header__bars .bars:after {
    width: 25px;
    height: 4px;
  }
  .header-wrapper .header__bars .bars:before {
    top: -9px;
  }
  .header-wrapper .header__bars .bars:after {
    bottom: -9px;
  }
}
@media (min-width: 1200px) {
  .header-wrapper .header__bars {
    display: none;
  }
}
.header-wrapper .navmenu {
  padding: 130px 30px 30px;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  max-height: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s ease;
}
.header-wrapper .navmenu.active {
  max-height: 100%;
  opacity: 1;
  visibility: visible;
  background: #0067FF;
}
@media screen and (min-width: 1200px) {
  .header-wrapper .navmenu {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: initial;
    visibility: visible;
    background: transparent;
    padding: 0;
    opacity: 1;
  }
}
.header-wrapper .language-block {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 1200px) {
  .header-wrapper .language-block {
    justify-content: flex-end;
  }
}
.header-wrapper .language-block .language-switch {
  border: 2px solid #fff;
  border-radius: 30px;
  padding: 2px;
}
.header-wrapper .language-block .switch-button {
  background: transparent;
  border-radius: 30px;
  overflow: hidden;
  width: 110px;
  text-align: center;
  font-family: "Graphik LCG";
  font-weight: normal;
  font-size: 18px;
  letter-spacing: 1px;
  color: #fff;
  position: relative;
  padding-right: 55px;
  text-transform: uppercase;
}
.header-wrapper .language-block .switch-button:before {
  content: "ru";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
}
.header-wrapper .language-block .switch-button-checkbox {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}
.header-wrapper .language-block .switch-button-checkbox:checked + .switch-button-label:before {
  transform: translateX(55px);
  transition: transform 300ms linear;
}
.header-wrapper .language-block .switch-button-checkbox + .switch-button-label {
  position: relative;
  padding: 11px 0;
  display: block;
  user-select: none;
  pointer-events: none;
}
.header-wrapper .language-block .switch-button-checkbox + .switch-button-label:before {
  content: "";
  background: linear-gradient(to bottom, #ff9400, #ff3d00);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 30px;
  transform: translateX(0);
  transition: transform 300ms;
}
.header-wrapper .language-block .switch-button-checkbox + .switch-button-label .switch-button-label-span {
  position: relative;
}
@media screen and (max-height: 899px) {
  .header-wrapper .language-block .switch-button {
    font-size: 16px;
  }
}
.header-wrapper .menu {
  padding: 0;
  margin: 0 0 40px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
@media screen and (min-width: 1200px) {
  .header-wrapper .menu {
    margin: 0 35px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }
}
.header-wrapper .menu li {
  margin: 0 0 25px;
}
.header-wrapper .menu li:last-child {
  margin: 0;
}
.header-wrapper .menu li a {
  display: flex;
  font-family: "Graphik LCG";
  font-style: normal;
  font-weight: normal;
  font-size: 27px;
  line-height: 1.25;
  color: #fff;
  text-decoration: none;
  transition: all 0.25s ease;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
  margin: 0 0 25px;
}
.header-wrapper .menu li a:last-child {
  margin: 0;
}
.header-wrapper .menu li a span {
  color: #ff5400;
  margin: 0 5px;
}
.header-wrapper .menu li a:before {
  content: "";
  width: 0;
  height: 5px;
  background: linear-gradient(to right, #ff9400, #ff3d00);
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: all 0.25s ease;
  box-shadow: 0 0 16px rgba(255, 84, 0, 0.8);
}
.header-wrapper .menu li a:hover {
  color: #fff;
  text-decoration: none;
}
.header-wrapper .menu li a:hover:before {
  width: 100%;
  opacity: 1;
}
.header-wrapper .menu li a.active {
  -webkit-text-fill-color: #fff;
  -webkit-text-stroke-color: #fff;
  -webkit-text-stroke-width: 0.3px;
}
.header-wrapper .menu li a.active:before {
  width: 100%;
  opacity: 1;
}
.header-wrapper .menu li.active a {
  -webkit-text-fill-color: #fff;
  -webkit-text-stroke-color: #fff;
  -webkit-text-stroke-width: 0.3px;
}
.header-wrapper .menu li.active a:before {
  width: 100%;
  opacity: 1;
}
@media screen and (min-width: 1200px) {
  .header-wrapper .menu li {
    margin: 0 35px 0 0;
  }
  .header-wrapper .menu li a {
    display: flex;
    font-family: "Graphik LCG";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.25;
    color: #fff;
    text-decoration: none;
    transition: all 0.25s ease;
    text-align: center;
    position: relative;
    padding-bottom: 0px;
  }
  .header-wrapper .menu li a span {
    color: #ff5400;
    margin: 0 5px;
  }
  .header-wrapper .menu li a:before {
    content: "";
    width: 0;
    height: 5px;
    background: linear-gradient(to right, #ff9400, #ff3d00);
    position: absolute;
    top: -68px;
    bottom: auto;
    left: 0;
    opacity: 0;
    transition: all 0.25s ease;
    box-shadow: 0 0 16px rgba(255, 84, 0, 0.8);
  }
  .header-wrapper .menu li a:hover {
    color: #fff;
    text-decoration: none;
  }
  .header-wrapper .menu li a:hover:before {
    width: 100%;
    opacity: 1;
  }
  .header-wrapper .menu li.active a {
    -webkit-text-fill-color: #fff;
    -webkit-text-stroke-color: #fff;
    -webkit-text-stroke-width: 0.3px;
  }
  .header-wrapper .menu li.active a:before {
    width: 100%;
    opacity: 1;
  }
}
@media screen and (max-height: 899px) {
  .header-wrapper .menu li a:before {
    top: -31px;
  }
}
@media screen and (max-width: 1199.9px) {
  .header-wrapper .menu li a:before {
    top: auto;
    bottom: -5px;
  }
}
.header-wrapper .menu a {
  display: flex;
  font-family: "Graphik LCG";
  font-style: normal;
  font-weight: normal;
  font-size: 27px;
  line-height: 1.25;
  color: #fff;
  text-decoration: none;
  transition: all 0.25s ease;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
  margin: 0 0 25px;
}
.header-wrapper .menu a:last-child {
  margin: 0;
}
.header-wrapper .menu a span {
  color: #ff5400;
  margin: 0 5px;
}
.header-wrapper .menu a:before {
  content: "";
  width: 0;
  height: 5px;
  background: linear-gradient(to right, #ff9400, #ff3d00);
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  transition: all 0.25s ease;
  box-shadow: 0 0 16px rgba(255, 84, 0, 0.8);
}
.header-wrapper .menu a:hover {
  color: #fff;
  text-decoration: none;
}
.header-wrapper .menu a:hover:before {
  width: 100%;
  opacity: 1;
}
.header-wrapper .menu a.active {
  -webkit-text-fill-color: #fff;
  -webkit-text-stroke-color: #fff;
  -webkit-text-stroke-width: 0.3px;
}
.header-wrapper .menu a.active:before {
  width: 100%;
  opacity: 1;
}
@media screen and (min-width: 1200px) {
  .header-wrapper .menu a {
    display: flex;
    font-family: "Graphik LCG";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.25;
    color: #fff;
    text-decoration: none;
    transition: all 0.25s ease;
    text-align: center;
    position: relative;
    padding-bottom: 0px;
    margin: 0 35px 0 0;
  }
  .header-wrapper .menu a span {
    color: #ff5400;
    margin: 0 5px;
  }
  .header-wrapper .menu a:before {
    content: "";
    width: 0;
    height: 5px;
    background: linear-gradient(to right, #ff9400, #ff3d00);
    position: absolute;
    top: -68px;
    bottom: auto;
    left: 0;
    opacity: 0;
    transition: all 0.25s ease;
    box-shadow: 0 0 16px rgba(255, 84, 0, 0.8);
  }
  .header-wrapper .menu a:hover {
    color: #fff;
    text-decoration: none;
  }
  .header-wrapper .menu a:hover:before {
    width: 100%;
    opacity: 1;
  }
  .header-wrapper .menu.active a {
    -webkit-text-fill-color: #fff;
    -webkit-text-stroke-color: #fff;
    -webkit-text-stroke-width: 0.3px;
  }
  .header-wrapper .menu.active a:before {
    width: 100%;
    opacity: 1;
  }
}
@media screen and (max-height: 899px) {
  .header-wrapper .menu a:before {
    top: -31px;
  }
}
@media screen and (max-width: 1199.9px) {
  .header-wrapper .menu a:before {
    top: auto;
    bottom: -5px;
  }
}

.modal .modal-content {
  background: url(../images/modal-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  border: 1px solid rgba(89, 91, 146, 0.25);
  border-radius: 0;
  position: relative;
}
.modal .modal-content .modal-body {
  padding: 45px 90px 90px;
  text-align: center;
}
@media screen and (max-width: 1199.9px) {
  .modal .modal-content .modal-body {
    padding: 25px 20px 50px;
  }
}
.modal .modal-content .modal-body h2 {
  margin-bottom: 40px;
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  z-index: 1;
}
@media screen and (max-width: 1199.9px) {
  .modal .modal-content .modal-body h2 {
    min-height: 100px;
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 991.9px) {
  .modal .modal-content .modal-body h2 {
    font-size: 38px;
  }
}
@media screen and (max-height: 899px) {
  .modal .modal-content .modal-body h2 {
    min-height: 70px;
  }
}
.modal .modal-content .modal-body .sub-title {
  display: block;
  font-family: "Graphik LCG";
  font-weight: 700;
  font-style: normal;
  font-size: 125px;
  line-height: 115px;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  top: 85px;
  color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: rgba(89, 91, 146, 0.25);
  -webkit-text-stroke-width: 1px;
  z-index: 0;
  width: 100%;
}
@media screen and (max-height: 899px) {
  .modal .modal-content .modal-body .sub-title {
    font-size: 80px;
    line-height: 100px;
    top: 30px;
  }
}
@media screen and (max-width: 1199.9px) {
  .modal .modal-content .modal-body .sub-title {
    font-size: 80px;
    line-height: 100px;
    top: 25px;
  }
}
@media screen and (max-width: 991.9px) {
  .modal .modal-content .modal-body .sub-title {
    font-size: 55px;
  }
}
.modal .modal-content .modal-body form {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}
@media screen and (max-width: 1199.9px) {
  .modal .modal-content .modal-body form {
    max-width: 500px;
  }
}
.modal .modal-content .modal-body form .form-group {
  margin-bottom: 30px;
}
@media screen and (max-height: 899px) {
  .modal .modal-content .modal-body form .form-group {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 1199.9px) {
  .modal .modal-content .modal-body form .form-group {
    margin-bottom: 20px;
  }
}
.modal .modal-content .modal-body form .btn {
  margin-top: 40px;
}
@media screen and (max-height: 899px) {
  .modal .modal-content .modal-body form .btn {
    margin-top: 10px;
  }
}
@media screen and (max-width: 1199.9px) {
  .modal .modal-content .modal-body form .btn {
    margin-top: 10px;
  }
}
.modal .modal-content .btn-close {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 99999;
  width: 30px;
  height: 30px;
  padding: 0;
  color: #fff;
  background: url(../images/btn-close.png) no-repeat center;
  opacity: 1;
}
@media screen and (max-height: 899px) {
  .modal .modal-content .btn-close {
    top: 15px;
    right: 15px;
  }
}
@media screen and (max-width: 1199.9px) {
  .modal .modal-content .btn-close {
    top: 15px;
    right: 15px;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 90%;
  }
}
body > canvas {
  opacity: 0.1;
}

.content_slide {
  width: 100%;
  min-height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  transition: 0.4s ease;
}

#fp-nav ul li,
.fp-slidesNav ul li {
  margin: 40px 7px;
}

#fp-nav ul li a span,
.fp-slidesNav ul li a span {
  background: #fff;
}

#fp-nav ul li a.active span,
#fp-nav ul li:hover a.active span,
.fp-slidesNav ul li a.active span,
.fp-slidesNav ul li:hover a.active span {
  background: #ff5400;
}

.section h2 {
  text-transform: uppercase;
  margin-bottom: 30px;
  transition: 1s ease;
}
@media screen and (min-width: 992px) {
  .section h2 {
    transform: translate(0, -300%);
  }
}
.section h3 {
  transition: 1s ease;
}
@media screen and (min-width: 992px) {
  .section h3 {
    transform: translate(0, -200%);
  }
}
@media screen and (min-width: 992px) {
  .section.active h2 {
    transform: translate(0, 0);
  }
  .section.active h3 {
    transform: translate(0, 0);
  }
  .section.active .about-block .text-column p {
    transform: translate(0, 0);
  }
  .section.active .advertisers-img-wrapper {
    transform: translate(0, 0);
  }
  .section.active .advertisers-info-block p {
    transform: translate(0, 0);
  }
  .section.active .advertisers-info-block .btn {
    transform: translate(0, 0);
  }
  .section.active .advantages-block {
    transform: translate(0, 0);
  }
  .section.active .youtube-img-parallax {
    transform: translate(0, 0);
  }
  .section.active .twitch-img-parallax {
    transform: translate(0, 0);
  }
  .section.active .blogers-img-wrapper {
    transform: translate(0, 0);
  }
  .section.active .blogers-info-block p {
    transform: translate(0, 0);
  }
  .section.active .blogers-info-block .btn {
    transform: translate(0, 0);
  }
}

#section0 {
  height: 100%;
  position: relative;
  justify-content: center;
  align-items: center;
}
#section0 #scene {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 100%;
  z-index: 1;
  overflow: hidden;
}
#section0 .banner {
  padding-top: 175px;
  text-align: center;
  position: relative;
  z-index: 2;
  will-change: transform;
}
#section0 .banner img {
  margin-bottom: 20px;
}
#section0 .banner h1 {
  margin-bottom: 20px;
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
}
#section0 .banner h1 span {
  display: block;
  font-size: 150px;
  line-height: 115px;
  text-transform: uppercase;
  position: absolute;
  width: 774px;
  left: calc(50% - 387px);
  top: 42px;
  color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: #595b92;
  -webkit-text-stroke-width: 1px;
  z-index: -1;
}
#section0 .banner p {
  margin-bottom: 80px;
  font-size: 25px;
  line-height: 50px;
  text-transform: capitalize;
}
#section0 .mercury-planet {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../images/mercury1.png);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  bottom: 0;
  margin-top: 5%;
  margin-left: 2%;
  z-index: 1;
  will-change: transform;
}
#section0 .banner-bg {
  position: absolute;
  width: 120%;
  height: 100%;
  margin-left: -10%;
  margin-top: -5%;
  background: url(../images/banner-bg.png);
  background-size: cover;
  background-position: center;
  z-index: -1;
  will-change: transform;
}
@media screen and (min-width: 767px) {
  #section0 .banner-bg {
    height: 120%;
  }
}
#section0 .mouse {
  position: absolute;
  bottom: 0;
  left: auto;
  right: auto;
  z-index: 1;
}

#section1 {
  padding: 155px 0;
}
#section1 h2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#section1 h2 span {
  display: block;
  font-size: 88px;
  line-height: 115px;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.2);
  -webkit-text-stroke-width: 1px;
}

#sub-section1 {
  padding: 155px 0;
}
#sub-section1 h2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#sub-section1 h2 span {
  display: block;
  font-size: 88px;
  line-height: 115px;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.2);
  -webkit-text-stroke-width: 1px;
}

#section2 {
  padding: 155px 0;
  position: relative;
  overflow-x: hidden;
}

#section3 {
  padding: 155px 0;
  position: relative;
  overflow-x: hidden;
}
#section3:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 0;
  width: 100%;
  height: 100%;
  background: url(../images/advantages-before.png) no-repeat left -160%;
  background-size: 318px auto;
}
#section3 .block-title {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 60px;
}
#section3 .block-title h3 {
  padding-left: 30px;
}

#sub-section3 {
  padding: 155px 0;
  position: relative;
  overflow-x: hidden;
  background: url(../images/advantages-bg.png) no-repeat center;
  background-size: 100% auto;
}

#section4 {
  padding: 155px 0;
  position: relative;
  overflow-x: hidden;
}

#section5 {
  padding: 155px 0 0;
  position: relative;
  overflow-x: hidden;
}
#section5 h2 {
  margin-bottom: 40px;
}

.about-block {
  width: 100%;
  display: grid;
  grid-template-columns: 45% 55%;
  margin-bottom: 100px;
}
.about-block .text-column {
  padding-right: 50px;
}
.about-block .text-column p {
  transition: 1s ease;
}
.about-block .text-column p:not(:last-child) {
  margin-bottom: 50px;
}
@media screen and (min-width: 992px) {
  .about-block .text-column p {
    transform: translate(0, 100%);
  }
}

.services-block {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
}
.services-block .single-service {
  border: 1px solid rgba(89, 91, 146, 0.25);
  border-radius: 6px;
  background: #070a32;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px 35px 35px;
  text-align: center;
  position: relative;
  height: 350px;
  transition: 1s ease;
}
.services-block .single-service img {
  height: 155px;
  width: auto;
  max-width: 100%;
  object-fit: contain;
  object-position: top;
  margin-bottom: 40px;
  transition: all 0.25s ease-in-out;
}
.services-block .single-service .icon {
  height: 155px;
  width: auto;
  max-width: 100%;
  margin-bottom: 40px;
  fill: #fff;
  transition: all 0.25s ease-in-out;
}
.services-block .single-service p {
  font-family: "Graphik LCG";
  font-weight: bold;
  font-style: normal;
  letter-spacing: 1px;
  color: #fff;
  margin-bottom: 0;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s ease-in-out;
}
.services-block .single-service .hidden-block {
  position: absolute;
  bottom: -25px;
  right: -35px;
  transform: scale(0, 0);
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  padding: 20px 10px 30px 30px;
  border-radius: 6px;
  width: 150%;
  height: auto;
  background: #12153b;
  border: 1px solid rgba(89, 91, 146, 0.25);
  box-shadow: 0 3px 53px rgba(0, 0, 0, 0.15);
  text-align: left;
  transition: all 0.25s ease-in-out;
}
.services-block .single-service .hidden-block h5 {
  font-family: "Graphik LCG";
  font-weight: bold;
  font-style: normal;
  font-size: 22px;
  line-height: 52px;
  letter-spacing: 1.48px;
  color: #fff;
  margin-bottom: 5px;
  transition: all 0.25s ease-in-out;
}
.services-block .single-service .hidden-block p {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 1px;
  color: #7986ae;
  min-height: auto;
  justify-content: flex-start;
  transition: all 0.25s ease-in-out;
}
.services-block .single-service:hover {
  transform: scale(1.4, 1.4);
  padding: 30px 15px 35px;
  background: #1e2145;
  z-index: 2;
  box-shadow: 0 0 90px rgba(255, 255, 255, 0.25);
}
.services-block .single-service:hover .icon {
  fill: url(#service-hover-gradient) #fff;
  -webkit-filter: drop-shadow(0px 0px 20px rgba(218, 68, 0, 0.91));
  filter: drop-shadow(0px 0px 20px rgba(218, 68, 0, 0.91));
}
.services-block .single-service:hover p {
  font-size: 0;
  line-height: 0;
  letter-spacing: 0;
}
.services-block .single-service:hover .hidden-block {
  transform: scale(1, 1);
  z-index: 1;
  opacity: 1;
}
.services-block .single-service:hover .hidden-block p {
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0;
}
.services-block .single-service:first-child .hidden-block {
  right: auto;
  left: -35px;
}

.brand-name {
  display: block;
  font-family: "Graphik LCG";
  font-weight: bold;
  font-style: normal;
  font-size: 88px;
  line-height: 115px;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.08);
  -webkit-text-stroke-width: 1px;
  position: absolute;
  left: -188px;
  bottom: calc(50% - 58px);
  transform: rotate(-90deg);
}

.advertisers-img-wrapper {
  position: absolute;
  bottom: calc(50% - 45vh);
  right: -6%;
  width: 50%;
  transition: 1s ease;
}
@media screen and (min-width: 992px) {
  .advertisers-img-wrapper {
    transform: translate(0, 40%);
  }
}

.advertisers-info-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
.advertisers-info-block h3 {
  margin-bottom: 50px;
  text-transform: uppercase;
}
.advertisers-info-block p {
  font-weight: 700;
  margin-bottom: 70px;
  transition: 1s ease;
}
@media screen and (min-width: 992px) {
  .advertisers-info-block p {
    transform: translate(0, -100%);
  }
}
.advertisers-info-block .btn {
  transition: 1s ease;
}
@media screen and (min-width: 992px) {
  .advertisers-info-block .btn {
    transform: translate(0, -100%);
  }
}

.advantages-block {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 50px;
  grid-column-gap: 50px;
  transition: 1s ease;
}
@media screen and (min-width: 992px) {
  .advantages-block {
    transform: translate(0, -100%);
  }
}
.advantages-block .single-advantage svg {
  margin-bottom: 45px;
  height: 85px;
  width: auto;
  fill: #fff;
}
.advantages-block .single-advantage p {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  margin: 0;
}

.advantages-banners {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 100px;
  position: relative;
}
.advantages-banners .youtube-img-parallax {
  margin-left: 30px;
  width: 260px;
  transition: 1s ease;
}
@media screen and (min-width: 992px) {
  .advantages-banners .youtube-img-parallax {
    transform: translate(0, -200%);
  }
}
.advantages-banners .youtube-img-parallax img {
  max-width: 100%;
}
.advantages-banners .twitch-img-parallax {
  margin-right: 30px;
  width: 240px;
  transition: 1s ease;
}
@media screen and (min-width: 992px) {
  .advantages-banners .twitch-img-parallax {
    transform: translate(0, -200%);
  }
}
.advantages-banners .twitch-img-parallax img {
  max-width: 100%;
}
.advantages-banners .advantages-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.advantages-banners .advantages-content .advantages-banner-text {
  padding: 0 15px;
}
.advantages-banners .advantages-content .advantages-banner-text h3 {
  font-size: 48px;
  margin-bottom: 30px;
  text-transform: uppercase;
}
.advantages-banners .advantages-content .advantages-banner-text .number {
  font-family: "Graphik LCG";
  font-weight: bold;
  font-style: normal;
  font-size: 70px;
  line-height: 97px;
  color: #fff;
  margin-bottom: 30px;
  text-transform: uppercase;
}
.advantages-banners .advantages-content .advantages-banner-text p {
  font-family: "Graphik LCG";
  font-weight: bold;
  font-style: normal;
  font-size: 16px;
  line-height: 28px;
  color: #fff;
  margin-bottom: 0;
  text-transform: uppercase;
}

.blogers-img-wrapper {
  position: absolute;
  bottom: calc(50% - 45vh);
  left: 0;
  width: 50%;
  transition: 1s ease;
}
@media screen and (min-width: 992px) {
  .blogers-img-wrapper {
    transform: translate(0, 40%);
  }
}

.blogers-info-block h2 {
  margin-bottom: 60px;
}
.blogers-info-block h3 {
  text-transform: uppercase;
  margin-bottom: 60px;
}
.blogers-info-block p {
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 40px;
  max-width: 640px;
  transition: 1s ease;
}
@media screen and (min-width: 992px) {
  .blogers-info-block p {
    transform: translate(0, 80%);
  }
}
.blogers-info-block .btn {
  margin-top: 40px;
  transition: 1s ease;
}
@media screen and (min-width: 992px) {
  .blogers-info-block .btn {
    transform: translate(0, 80%);
  }
}

.map-wrapper {
  width: 100%;
  position: relative;
}
.map-wrapper .map-block {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.map-wrapper .map-block iframe {
  width: 100%;
  height: 100%;
  margin-bottom: -15px;
}
.map-wrapper .contacts-wrapper {
  width: 490px;
  margin: 30px 0 30px auto;
  background: #020730;
  border: 1px solid #595b92;
  text-align: center;
  padding: 40px 35px;
  position: relative;
  z-index: 2;
}
.map-wrapper .contacts-wrapper .contacts-block-title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
  position: relative;
}
.map-wrapper .contacts-wrapper .contacts-block-title img {
  height: 80px;
  width: auto;
  margin: 0 auto 20px;
}
.map-wrapper .contacts-wrapper .contacts-block-title h3 {
  font-size: 48px;
  line-height: 65px;
  text-transform: uppercase;
}
.map-wrapper .contacts-wrapper .contacts-block-title span {
  display: block;
  font-family: "Graphik LCG";
  font-weight: bold;
  font-style: normal;
  font-size: 80px;
  line-height: 110px;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: rgba(255, 255, 255, 0.1);
  -webkit-text-stroke-width: 1px;
  position: absolute;
  left: 0;
  bottom: -13px;
  width: 100%;
}
.map-wrapper .contacts-wrapper .contacts-blocks {
  margin-bottom: 60px;
}
.map-wrapper .contacts-wrapper .contacts-blocks .single-contact-block {
  margin-bottom: 15px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.map-wrapper .contacts-wrapper .contacts-blocks .single-contact-block svg {
  width: 35px;
  height: 35px;
  fill: #fff;
  margin-right: 20px;
}
.map-wrapper .contacts-wrapper .contacts-blocks .single-contact-block p {
  width: calc(100% - 55px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}
.map-wrapper .contacts-wrapper .contacts-blocks .single-contact-block p span {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  line-height: 40px;
}
.map-wrapper .contacts-wrapper .contacts-blocks .single-contact-block ul {
  width: 100%;
  padding: 0;
  margin: 15px 0 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.map-wrapper .contacts-wrapper .contacts-blocks .single-contact-block ul li {
  margin: 0 10px;
}
.map-wrapper .contacts-wrapper .contacts-blocks .single-contact-block ul li a svg {
  fill: #fff;
  width: 50px;
  height: 50px;
}
.map-wrapper .contacts-wrapper .email-block {
  margin-bottom: 30px;
}
.map-wrapper .contacts-wrapper .email-block svg {
  fill: #fff;
  width: 50px;
  height: 35px;
  margin-bottom: 15px;
}
.map-wrapper .contacts-wrapper .email-block p {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  line-height: 22px;
}
.map-wrapper .contacts-wrapper .email-block a {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  line-height: 22px;
  color: #fff;
  text-decoration: none;
}
.map-wrapper .contacts-wrapper .email-block a:hover {
  color: #ff5400;
}
.map-wrapper .contacts-wrapper .user-block svg {
  fill: #fff;
  width: 40px;
  height: 40px;
  margin-bottom: 15px;
}
.map-wrapper .contacts-wrapper .user-block p {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  line-height: 22px;
}
.map-wrapper .contacts-wrapper .user-block a {
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 20px;
  line-height: 22px;
  color: #fff;
  text-decoration: none;
}
.map-wrapper .contacts-wrapper .user-block a:hover {
  color: #ff5400;
}

.fp-warning, .fp-watermark {
  display: none;
}

/* Responsive */
@media screen and (min-width: 767px) {
  .fp-enabled .content_slide {
    opacity: 0;
  }
  .fp-enabled .content_slide.active {
    opacity: 1;
  }
}
@media screen and (max-height: 899px) {
  #section0 .banner {
    padding-top: 125px;
  }
  #section0 .banner img {
    margin-bottom: 0;
    max-height: 80px;
  }
  #section0 .banner h1 {
    min-height: 120px;
    margin-bottom: 0;
  }
  #section0 .banner h1 span {
    font-size: 90px;
    line-height: 90px;
    top: 15px;
  }
  #section0 .banner p {
    font-size: 20px;
    line-height: 30px;
  }
  #section0 .mouse {
    max-width: 25px;
  }
  #section0 .mercury-planet {
    background-size: 85%;
  }

  #section1 {
    padding: 100px 0 50px;
  }
  #section1 h2 span {
    font-size: 45px;
    line-height: 65px;
  }

  #sub-section1 {
    padding: 100px 0 50px;
  }
  #sub-section1 h2 span {
    font-size: 45px;
    line-height: 65px;
  }

  #section2 {
    padding: 100px 0 50px;
  }

  #section3 {
    padding: 100px 0 50px;
  }

  #sub-section3 {
    padding: 50px 0 50px;
  }

  #section4 {
    padding: 100px 0 50px;
  }

  .about-block {
    margin-bottom: 50px;
  }
  .about-block .text-column p:not(:last-child) {
    margin-bottom: 30px;
  }

  .services-block .single-service {
    height: 270px;
  }
  .services-block .single-service .icon {
    height: 100px;
    margin-bottom: 20px;
  }
  .services-block .single-service:hover p {
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 1.48px;
  }
  .services-block .single-service:hover .hidden-block h5 {
    font-size: 18px;
    line-height: 35px;
  }
  .services-block .single-service:hover .hidden-block p {
    font-size: 14px;
    line-height: 25px;
  }
  .services-block .single-service .hidden-block {
    padding: 20px;
  }
  .services-block .single-service .hidden-block h5 {
    font-size: 18px;
    line-height: 35px;
  }
  .services-block .single-service .hidden-block p {
    font-size: 14px;
    line-height: 25px;
  }

  .advertisers-img-wrapper {
    max-height: 90vh;
    width: 50%;
  }
  .advertisers-img-wrapper #advertisers-scene {
    max-height: 90vh;
  }
  .advertisers-img-wrapper img {
    height: 90vh;
    max-height: 90vh;
    width: auto;
  }

  .advertisers-info-block h3 {
    margin-bottom: 30px;
  }
  .advertisers-info-block p {
    margin-bottom: 40px;
  }

  .brand-name {
    font-size: 80px;
    line-height: 100px;
    left: -170px;
    bottom: calc(50% - 40px);
  }

  .advantages-banners {
    margin-top: 60px;
  }
  .advantages-banners .advantages-content .advantages-banner-text h3 {
    font-size: 30px;
    line-height: 34px;
  }
  .advantages-banners .advantages-content .advantages-banner-text .number {
    font-size: 50px;
    line-height: 80px;
  }

  .blogers-img-wrapper {
    max-height: 90vh;
    width: 50%;
  }
  .blogers-img-wrapper #blogers-scene {
    max-height: 90vh;
  }
  .blogers-img-wrapper img {
    height: 90vh;
    max-height: 90vh;
    width: auto;
  }

  .blogers-info-block h2 {
    margin-bottom: 30px;
  }
  .blogers-info-block h3 {
    margin-bottom: 30px;
  }
  .blogers-info-block p {
    margin-bottom: 20px;
  }
  .blogers-info-block .btn {
    margin-top: 0px;
    margin-bottom: 40px;
  }
}
@media screen and (max-height: 768px) {
  #section0 .banner {
    padding-top: 125px;
  }
  #section0 .banner img {
    margin-bottom: 0;
    max-height: 100px;
  }
  #section0 .banner h1 {
    min-height: 120px;
    margin-bottom: 15px;
    font-size: 70px;
  }
  #section0 .banner h1 span {
    font-size: 120px;
    line-height: 90px;
    top: 15px;
  }
  #section0 .banner p {
    font-size: 24px;
    line-height: 30px;
  }
  #section0 .mercury-planet {
    background-size: 100%;
  }

  .services-block .single-service .hidden-block {
    bottom: 10px;
  }
}
@media screen and (max-width: 1499.9px) {
  .advantages-banners .youtube-img-parallax {
    margin-left: 20px;
    width: 200px;
  }
  .advantages-banners .youtube-img-parallax img {
    max-width: 100%;
  }
  .advantages-banners .twitch-img-parallax {
    margin-right: 20px;
    width: 200px;
  }
  .advantages-banners .twitch-img-parallax img {
    max-width: 100%;
  }

  .brand-name {
    font-size: 50px;
    line-height: 70px;
    left: -105px;
  }

  .advantages-banners .advantages-content .advantages-banner-text h3 {
    margin-bottom: 10px;
  }
  .advantages-banners .advantages-content .advantages-banner-text .number {
    font-size: 40px;
    line-height: 70px;
  }

  .services-block .single-service {
    padding: 20px 15px 25px;
    height: 250px;
  }
  .services-block .single-service > p {
    min-height: 60px;
  }
  .services-block .single-service .hidden-block {
    padding: 20px;
    bottom: 20px;
    right: 0;
  }
  .services-block .single-service:first-child .hidden-block {
    right: auto;
    left: 0px;
  }
  .services-block .single-service:hover {
    transform: scale(1.2, 1.2);
    padding: 20px 15px 25px;
  }
}
@media screen and (max-width: 1380px) {
  .services-block .single-service .hidden-block p {
    font-size: 12px;
    line-height: 20px;
  }
  .services-block .single-service:hover .hidden-block p {
    font-size: 12px;
    line-height: 20px;
  }

  .advantages-banners .advantages-content .advantages-banner-text h3 {
    font-size: 26px;
    line-height: 30px;
  }
  .advantages-banners .advantages-content .advantages-banner-text .number {
    font-size: 36px;
    line-height: 66px;
  }
  .advantages-banners .advantages-content .advantages-banner-text p {
    font-size: 14px;
    line-height: 24px;
  }
  .advantages-banners .youtube-img-parallax {
    margin-left: 10px;
    width: 260px;
  }
  .advantages-banners .twitch-img-parallax {
    margin-right: 10px;
    width: 240px;
  }

  .advantages-block .single-advantage svg {
    margin-bottom: 25px;
    height: 65px;
  }
  .advantages-block .single-advantage p {
    font-size: 14px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1279.9px) {
  .services-block .single-service .hidden-block p {
    font-size: 12px;
    line-height: 20px;
  }
  .services-block .single-service:hover .hidden-block p {
    font-size: 12px;
    line-height: 20px;
  }

  .advantages-banners .advantages-content .advantages-banner-text h3 {
    font-size: 26px;
    line-height: 30px;
  }
  .advantages-banners .advantages-content .advantages-banner-text .number {
    font-size: 36px;
    line-height: 66px;
  }
  .advantages-banners .advantages-content .advantages-banner-text p {
    font-size: 14px;
    line-height: 24px;
  }
  .advantages-banners .youtube-img-parallax {
    margin-left: 10px;
    width: 150px;
  }
  .advantages-banners .twitch-img-parallax {
    margin-right: 10px;
    width: 150px;
  }

  .advantages-block .single-advantage svg {
    margin-bottom: 25px;
    height: 65px;
  }
  .advantages-block .single-advantage p {
    font-size: 14px;
    line-height: 24px;
  }
}
@media screen and (max-width: 1199.9px) {
  .section h2 {
    margin-bottom: 20px;
  }

  #section0 .banner {
    padding-top: 0;
  }
  #section0 #scene {
    justify-content: center;
  }
  #section0 .mouse {
    max-width: 16px;
    left: calc(50% - 8px);
    right: calc(50% - 8px);
  }

  .about-block {
    margin-bottom: 30px;
  }
  .about-block .text-column p br {
    display: none;
  }

  .services-block .single-service {
    height: 180px;
  }
  .services-block .single-service p {
    font-size: 12px;
    line-height: 20px;
    min-height: 40px;
  }
  .services-block .single-service .icon {
    height: 70px;
  }
  .services-block .single-service .hidden-block {
    right: -20px;
    bottom: 0;
    width: 180%;
  }
  .services-block .single-service .hidden-block h5 {
    font-size: 16px;
    line-height: 25px;
  }
  .services-block .single-service .hidden-block p {
    font-size: 10px;
    line-height: 20px;
  }
  .services-block .single-service:first-child .hidden-block {
    right: auto;
    left: -20px;
  }
  .services-block .single-service:hover .hidden-block h5 {
    font-size: 15px;
    line-height: 25px;
  }
  .services-block .single-service:hover .hidden-block p {
    font-size: 10px;
    line-height: 20px;
  }

  .advertisers-img-wrapper {
    max-height: 80vh;
    top: 20vh;
  }
  .advertisers-img-wrapper #advertisers-scene {
    max-height: 80vh;
  }
  .advertisers-img-wrapper img {
    height: 60vh;
    max-height: 60vh;
    width: auto;
  }

  .advantages-block {
    grid-row-gap: 20px;
    grid-column-gap: 30px;
  }
  .advantages-block .single-advantage svg {
    margin-bottom: 20px;
    height: 60px;
  }
  .advantages-block .single-advantage p {
    font-size: 12px;
    line-height: 22px;
  }

  .advantages-banners {
    margin-top: 50px;
  }
  .advantages-banners .advantages-content .advantages-banner-text h3 {
    font-size: 24px;
    line-height: 28px;
  }
  .advantages-banners .advantages-content .advantages-banner-text .number {
    font-size: 34px;
    line-height: 60px;
  }
  .advantages-banners .advantages-content .advantages-banner-text p {
    font-size: 10px;
    line-height: 12px;
  }

  .blogers-img-wrapper {
    max-height: 80vh;
    top: 20vh;
  }
  .blogers-img-wrapper #blogers-scene {
    max-height: 80vh;
  }
  .blogers-img-wrapper img {
    height: 60vh;
    max-height: 60vh;
    width: auto;
  }

  .blogers-info-block p {
    font-size: 14px;
    line-height: 24px;
  }
  .blogers-info-block p br {
    display: none;
  }

  .map-wrapper .contacts-wrapper {
    margin: 20px 0 20px auto;
    padding: 20px 25px;
    width: 370px;
  }
  .map-wrapper .contacts-wrapper .contacts-block-title {
    margin-bottom: 20px;
  }
  .map-wrapper .contacts-wrapper .contacts-block-title img {
    height: 60px;
    margin: 0 auto 10px;
  }
  .map-wrapper .contacts-wrapper .contacts-block-title span {
    font-size: 60px;
    line-height: 70px;
    bottom: 4px;
  }
  .map-wrapper .contacts-wrapper .contacts-blocks {
    margin-bottom: 40px;
  }
  .map-wrapper .contacts-wrapper .contacts-blocks .single-contact-block svg {
    width: 20px;
    height: 20px;
    margin-right: 15px;
  }
  .map-wrapper .contacts-wrapper .contacts-blocks .single-contact-block p {
    width: calc(100% - 35px);
  }
  .map-wrapper .contacts-wrapper .contacts-blocks .single-contact-block p span {
    font-size: 16px;
    line-height: 24px;
  }
  .map-wrapper .contacts-wrapper .contacts-blocks .single-contact-block ul {
    margin: 0px 0 0;
  }
  .map-wrapper .contacts-wrapper .contacts-blocks .single-contact-block ul li {
    margin: 0 2px;
  }
  .map-wrapper .contacts-wrapper .contacts-blocks .single-contact-block ul li a svg {
    width: 30px;
    height: 30px;
  }
  .map-wrapper .contacts-wrapper .email-block svg {
    width: 25px;
    height: 25px;
    margin-bottom: 5px;
  }
  .map-wrapper .contacts-wrapper .email-block p {
    font-size: 16px;
    line-height: 22px;
    margin: 0;
  }
  .map-wrapper .contacts-wrapper .email-block a {
    font-size: 16px;
    line-height: 22px;
  }
  .map-wrapper .contacts-wrapper .user-block svg {
    width: 25px;
    height: 25px;
    margin-bottom: 5px;
  }
  .map-wrapper .contacts-wrapper .user-block p {
    font-size: 16px;
    line-height: 22px;
    margin: 0;
  }
  .map-wrapper .contacts-wrapper .user-block a {
    font-size: 16px;
    line-height: 22px;
  }

  #section5 {
    padding: 100px 0 0;
  }
  #section5 h2 {
    margin-bottom: 30px;
  }

  .brand-name {
    bottom: 100px;
  }
}
@media screen and (max-width: 991.9px) {
  #section0 {
    min-height: 100vh;
    padding: 0;
    justify-content: center;
    overflow-x: hidden;
  }
  #section0 #scene {
    min-height: 100vh;
  }
  #section0 .banner-bg {
    margin-left: 0;
    margin-top: 0;
  }
  #section0 .mercury-planet {
    margin-left: 2%;
    margin-top: 0;
  }
  #section0 .mouse {
    max-width: 10px;
    left: calc(50% - 5px);
    right: calc(50% - 5px);
  }
  #section0 .banner img {
    margin-bottom: 0;
    max-height: 80px;
  }
  #section0 .banner h1 {
    min-height: auto;
    margin-bottom: 15px;
    font-size: 46px;
  }
  #section0 .banner h1 span {
    font-size: 65px;
    line-height: 65px;
    top: 11px;
  }
  #section0 .banner p {
    font-size: 20px;
  }

  #section1 {
    padding: 100px 0 50px;
  }

  #sub-section1 {
    padding: 80px 0 50px;
  }
  #sub-section1 h2 span {
    font-size: 50px;
  }

  .about-block {
    grid-template-columns: 100%;
    margin-bottom: 0;
  }

  .services-block {
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 30px;
  }
  .services-block .single-service .hidden-block {
    right: 0;
  }
  .services-block .single-service:first-child .hidden-block {
    right: auto;
    left: 0;
  }
  .services-block .single-service:nth-child(3) .hidden-block {
    right: auto;
    left: 0;
  }

  .brand-name {
    display: none;
  }

  #section2 {
    padding: 80px 0 50px;
    overflow-x: hidden;
  }

  .advertisers-img-wrapper {
    max-height: none;
    top: auto;
    left: 0;
    bottom: auto;
    position: relative;
    width: 100%;
  }
  .advertisers-img-wrapper #advertisers-scene {
    max-height: none;
  }
  .advertisers-img-wrapper img {
    height: auto;
    max-height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
  }
  .advertisers-img-wrapper img:last-child {
    position: absolute;
    top: 0;
    left: 0;
  }

  #section3 {
    padding: 80px 0 50px;
    flex-wrap: wrap;
  }
  #section3 .block-title {
    flex-wrap: wrap;
  }
  #section3 .block-title h3 {
    padding-left: 0;
    width: 100%;
  }

  #sub-section3 {
    padding: 50px 0 50px;
    flex-wrap: wrap;
    background-size: 100% 100%;
  }

  .advantages-block {
    grid-template-columns: repeat(2, 1fr);
  }

  .youtube-img-parallax {
    display: none;
  }

  .twitch-img-parallax {
    display: none;
  }

  .advantages-banners .advantages-content {
    flex-wrap: wrap;
  }
  .advantages-banners .advantages-content .advantages-banner-text {
    padding: 0 0 0 150px;
    width: 100%;
    margin-bottom: 40px;
    position: relative;
  }
  .advantages-banners .advantages-content .advantages-banner-text h3 {
    margin-bottom: 0px;
  }
  .advantages-banners .advantages-content .advantages-banner-text:first-child:before {
    content: "";
    width: 150px;
    height: 110px;
    position: absolute;
    top: auto;
    left: 0;
    background: url(../images/youtube.png) no-repeat center;
    background-size: auto 100%;
  }
  .advantages-banners .advantages-content .advantages-banner-text:last-child:before {
    content: "";
    width: 150px;
    height: 110px;
    position: absolute;
    top: auto;
    left: 0;
    background: url(../images/twitch.png) no-repeat center;
    background-size: auto 100%;
  }

  #section4 {
    padding: 80px 0 50px;
    overflow-x: hidden;
  }

  .blogers-img-wrapper {
    max-height: none;
    top: auto;
    left: 0;
    bottom: auto;
    position: relative;
    width: 100%;
    z-index: -1;
  }
  .blogers-img-wrapper #blogers-scene {
    max-height: none;
  }
  .blogers-img-wrapper img {
    height: auto;
    max-height: 100%;
    width: 100%;
    object-fit: contain;
    object-position: center;
  }
  .blogers-img-wrapper img:last-child {
    position: absolute;
    top: 0;
    left: 0;
  }

  #section5 {
    padding: 80px 0 0;
    flex-wrap: wrap;
    overflow-x: hidden;
  }

  .map-wrapper .map-block {
    position: relative;
    top: auto;
    left: auto;
    height: 300px;
  }
  .map-wrapper .contacts-wrapper {
    margin: 0;
    padding: 20px 25px;
    width: 100%;
  }
}
@media screen and (max-width: 575.9px) {
  #sub-section1 h2 span {
    font-size: 40px;
  }

  .advantages-banners .advantages-content .advantages-banner-text .number {
    font-size: 30px;
    line-height: 60px;
  }

  .header-wrapper .logo-block .logo img {
    max-width: 125px;
  }
}
@media screen and (max-width: 479.9px) {
  #section0 .banner h1 span {
    font-size: 60px;
    line-height: 60px;
  }

  .about-block .text-column {
    padding-right: 0;
  }

  #sub-section1 h2 span {
    font-size: 40px;
    display: none;
  }

  .services-block {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 0px;
  }

  .services-block .single-service:hover {
    transform: scale(1, 1);
  }

  .services-block .single-service .hidden-block {
    right: 0;
    left: 0;
    max-width: 300px;
    width: 100%;
    padding: 10px;
    text-align: center;
  }

  .services-block .single-service:first-child .hidden-block {
    right: 0;
    left: 0;
  }

  .services-block .single-service:nth-child(3) .hidden-block {
    right: 0;
    left: 0;
  }

  .advantages-block {
    grid-template-columns: repeat(1, 1fr);
  }

  .advantages-banners {
    margin-top: 20px;
  }

  .advantages-banners .advantages-content .advantages-banner-text {
    padding: 120px 0 0;
    text-align: center;
  }

  .advantages-banners .advantages-content .advantages-banner-text:first-child:before {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  .advantages-banners .advantages-content .advantages-banner-text:last-child:before {
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }

  .modal .modal-content .modal-body h2 {
    font-size: 28px;
  }

  .modal .modal-content .modal-body .sub-title {
    font-size: 34px;
  }

  .map-wrapper .contacts-wrapper .contacts-block-title span {
    font-size: 50px;
    line-height: 70px;
    bottom: 4px;
  }

  .map-wrapper .contacts-wrapper .contacts-block-title h3 {
    font-size: 35px;
    line-height: 45px;
    text-transform: uppercase;
  }

  #section3 .block-title {
    text-align: center;
    justify-content: center;
  }

  .advantages-block .single-advantage {
    text-align: center;
    margin-bottom: 20px;
  }
}
.breadcrumbs-block {
  width: 100%;
  padding: 13px 0 10px;
  border-bottom: 1px solid #E9E9E9;
}
.breadcrumbs-block .breadcrumbs {
  display: flex;
  align-items: baseline;
}
.breadcrumbs-block .breadcrumbs span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #fff;
}
.breadcrumbs-block .breadcrumbs a {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #ff5400;
  position: relative;
  display: flex;
  align-items: baseline;
  margin-right: 10px;
  text-decoration: none;
  transition: all 0.25s ease-in-out;
}
.breadcrumbs-block .breadcrumbs a:hover {
  opacity: 0.8;
}
.breadcrumbs-block .breadcrumbs a:after {
  content: "/";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  color: #ff5400;
  margin-left: 10px;
}